import React from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import "./CourseParticipant.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import TestMode from "../../components/TestMode";
import SubTitle from "../../components/SubTitle";
import Input from "../../components/Input";
import {
  dateDurationToFullStr,
  dateToFullStr,
  dateToSmallStr,
  firebaseDateToDateObj,
  getMonthNum,
  makeid,
  minTimeToStr,
  monthToDate,
  priceToStr,
  reformatDate,
  reformatDateReverse,
  strTimeToMin,
} from "../../functions/utils";
import Title from "../../components/Title";
import PaymentStatus from "../../components/PaymentStatus";
import SwitchSetting from "../../components/SwitchSetting";
import ErrorMessage from "../../components/ErrorMessage";
import SecButton from "../../components/SecButton";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { MainContext } from "../../contexts/MainContext";
import { getPaymentTable } from "../../functions/getPaymentTable";
import MonthSelector from "../../components/MonthSelector";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBookingStatus } from "../../functions/getBookingStatus";
import ClipLoader from "react-spinners/ClipLoader";
import qs from "qs";
import { molliePaymentStatus, weekDays } from "../../services/constants";
import PaymentMethod from "../../components/PaymentMethod";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId: string;
    courseParticipantId: string;
  }>;

export default class CourseParticipant extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseBookingId: string;
    branchLoaded: boolean;
    branch: any;
    courseBookingLoaded: boolean;
    courseBookingData: any;
    email: string;
    fName: string;
    lName: string;
    phoneNumber: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    birthDate: string;
    note: string;
    paid: boolean;
    errorMessage: null | string;
    updateLoading: boolean;
    change: boolean;
    changeUserData: boolean;
    errorMessageUserData: null | string;
    updateLoadingUserData: boolean;
    showConfirmationModal: boolean;
    deleteLoading: boolean;
    errorMessageDelete: null | string;
    redirect: null | string;
    errorMsgCancellation: null | string;
    cancellationLoading: boolean;
    refundLoading: boolean;
    showConfirmationModalCancel: boolean;
    showConfirmationModalRefund: boolean;
    showConfirmationModalRetry: boolean;
    refundNote: string;
    paymentArr: Array<any>;
    startMonthNum: number;
    endMonthNum: number;
    updatePauseLoading: boolean;
    pausedMonths: any;
    cancelMonth: number;
    refundMonth: number;
    retryMonth: number;
    userId: string;
    backLink: string | null | any;
    courseDateStr: string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseId: this.props.match.params.courseId,
      courseBookingId: this.props.match.params.courseParticipantId,
      branchLoaded: false,
      branch: {},
      courseBookingLoaded: false,
      courseBookingData: {},
      email: "",
      fName: "",
      lName: "",
      phoneNumber: "",
      street: "",
      houseNumber: "",
      zipcode: "",
      city: "",
      birthDate: "",
      note: "",
      paid: false,
      errorMessage: null,
      updateLoading: false,
      change: false,
      changeUserData: false,
      errorMessageUserData: null,
      updateLoadingUserData: false,
      showConfirmationModal: false,
      deleteLoading: false,
      errorMessageDelete: null,
      redirect: null,
      errorMsgCancellation: null,
      cancellationLoading: false,
      refundLoading: false,
      showConfirmationModalCancel: false,
      showConfirmationModalRefund: false,
      showConfirmationModalRetry: false,
      refundNote: "",
      paymentArr: [],
      startMonthNum: getMonthNum(new Date()) + 1,
      endMonthNum: getMonthNum(new Date()) + 1,
      updatePauseLoading: false,
      pausedMonths: {},
      cancelMonth: 0,
      refundMonth: 0,
      retryMonth: 0,
      userId: "",
      backLink:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).back || null,
      courseDateStr: "",
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestCourseBooking();
    this.requestCourse();
  };

  requestCourse = () => {
    fetchRetry(
      "getCourseFromIdFNew",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleCourseSuccess)
      .catch(this.handleError);
  };

  handleCourseSuccess = ({ data }: any) => {
    if (data.success) {
      const courseData = data.data[0];
      const courseTitle = courseData.title;
      let courseString;
      if (courseData.courseType === "infinity-course") {
        courseString = `jeden ${weekDays[courseData.weekDay].name} um ${
          courseData.time
        } Uhr - ${minTimeToStr(
          strTimeToMin(courseData.time) + courseData.duration
        )} Uhr`;
      } else {
        courseString =
          dateDurationToFullStr(
            firebaseDateToDateObj(courseData.startTime),
            firebaseDateToDateObj(courseData.endTime)
          ) || "";
      }
      this.context.setCustomPath({
        link: `/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}/participant/${this.state.courseBookingId}`,
        name: courseTitle,
      });
      this.setState({
        courseDateStr: courseString,
      });
    }
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  requestCourseBooking = () => {
    fetchRetry(
      "getCourseBookingF",
      {
        courseBookingId: this.state.courseBookingId,
        // targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingSuccess)
      .catch(this.handleError);
  };

  requestCoursesBookingSuccess = async ({ data }: any) => {
    this.setState({ courseBookingLoaded: true });
    if (data.success) {
      const courseBookingData = data.data;
      // createPayment list
      // reformat subscription Payments
      const isCustom = courseBookingData?.method === "manuell";
      const paymentArr: Array<any> = getPaymentTable(
        courseBookingData,
        isCustom
      );

      this.setState(
        {
          paymentArr,
          courseBookingData: courseBookingData,
          fName: courseBookingData?.user?.fName,
          lName: courseBookingData?.user?.lName,
          email: courseBookingData?.user?.email
            ? courseBookingData.user.email
            : "",
          street: courseBookingData?.user?.address?.street
            ? courseBookingData.user.address.street
            : "",
          city: courseBookingData?.user?.address?.city
            ? courseBookingData.user.address.city
            : "",
          houseNumber: courseBookingData?.user?.address?.houseNumber
            ? courseBookingData.user.address.houseNumber
            : "",
          zipcode: courseBookingData?.user?.address?.zipcode
            ? courseBookingData.user.address.zipcode
            : "",
          birthDate: reformatDateReverse(courseBookingData?.user?.birthDate),
          phoneNumber: courseBookingData?.user?.phoneNumber
            ? courseBookingData.user.phoneNumber
            : "",
          note: courseBookingData.note,
          paid: courseBookingData.paid,
          pausedMonths: courseBookingData.pausedMonths,
          userId: courseBookingData.userId,
        },
        () => {
          this.checkChange();
          this.checkChangeUserData();
        }
      );
    }
  };

  updateCourseBooking = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
        paid: this.state.paid,
        note: this.state.note,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingUpdatedSuccess)
      .catch(this.handleError);
  };

  updateCourseBookingUserData = () => {
    this.setState({ updateLoadingUserData: true });
    fetchRetry(
      "updateCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
        fName: this.state.fName,
        lName: this.state.lName,
        address: {
          city: this.state.city,
          houseNumber: this.state.houseNumber,
          street: this.state.street,
          zipcode: this.state.zipcode,
        },
        birthDate: reformatDate(this.state.birthDate),
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingUserDataUpdatedSuccess)
      .catch(this.handleError);
  };

  requestCoursesBookingUserDataUpdatedSuccess = ({ data }: any) => {
    this.setState({ updateLoadingUserData: false });
    if (data.success) {
      this.requestCourseBooking();
      this.setState({ errorMessageUserData: null });
    } else {
      this.setState({ errorMessageUserData: data.errorMsgDe });
    }
  };

  requestCoursesBookingUpdatedSuccess = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestCourseBooking();
      this.setState({ errorMessage: null });
    } else {
      this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  handleRefundNoteChange = (val: any) => {
    this.setState({ refundNote: val });
  };

  handleEmailChange = (val: any) => {
    this.setState({ email: val }, this.checkChangeUserData);
  };

  handleFNameChange = (val: any) => {
    this.setState({ fName: val }, this.checkChangeUserData);
  };

  handleLNameChange = (val: any) => {
    this.setState({ lName: val }, this.checkChangeUserData);
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val }, this.checkChangeUserData);
  };

  handleCityChange = (val: any) => {
    this.setState({ city: val }, this.checkChangeUserData);
  };

  handleHouseNumberChange = (val: any) => {
    this.setState({ houseNumber: val }, this.checkChangeUserData);
  };

  handleZipcodeChange = (val: any) => {
    this.setState({ zipcode: val }, this.checkChangeUserData);
  };

  handleStreetChange = (val: any) => {
    this.setState({ street: val }, this.checkChangeUserData);
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value }, this.checkChangeUserData);
  };

  togglePaid = () => {
    this.setState({ paid: !this.state.paid }, this.checkChange);
  };

  handleNoteChange = (val: any) => {
    this.setState({ note: val }, this.checkChange);
  };

  handleDeleteCourseBooking = () => {
    this.setState({ showConfirmationModal: true });
  };

  handleDeleteCourseBookingConfirm = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingDeleteSuccess)
      .catch(this.handleError);
  };

  requestCoursesBookingDeleteSuccess = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.setState({
        redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}`,
      });
      this.context.createInfo(
        "Die manuelle Buchung wurde erfolgreich gelöscht.",
        "success",
        4
      );
    } else {
      this.setState({ errorMessageDelete: data.errorMsgDe });
    }
  };

  handleCancellateCourse = () => {
    this.setState({ showConfirmationModalCancel: true });
  };

  handleCancellateCourseConfirmed = () => {
    this.setState({
      cancellationLoading: true,
      showConfirmationModalCancel: false,
    });
    fetchRetry(
      "createCourseRefund",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        courseBookingId: this.state.courseBookingId,
        refundNote: this.state.refundNote,
      },
      1,
      5
    )
      .then(this.createCancellationSuccess)
      .catch(this.handleError);
  };

  createCancellationSuccess = ({ data }: any) => {
    this.setState({ cancellationLoading: false });
    if (data.success) {
      this.requestCourseBooking();
      this.setState({ errorMsgCancellation: null });
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.courseBookingData.note !== this.state.note ||
        this.state.courseBookingData.paid !== this.state.paid,
    });
  };

  checkChangeUserData = () => {
    /* eslint eqeqeq: 0 */
    this.setState({
      changeUserData:
        this.state.courseBookingData.user?.fName != this.state.fName ||
        this.state.courseBookingData.user?.lName != this.state.lName ||
        this.state.courseBookingData.user?.email != this.state.email ||
        reformatDateReverse(this.state.courseBookingData.user?.birthDate) !=
          this.state.birthDate ||
        this.state.courseBookingData.user?.phoneNumber !=
          this.state.phoneNumber ||
        this.state.courseBookingData.user?.address?.city != this.state.city ||
        this.state.courseBookingData.user?.address?.houseNumber !=
          this.state.houseNumber ||
        this.state.courseBookingData.user?.address?.street !=
          this.state.street ||
        this.state.courseBookingData.user?.address?.zipcode !=
          this.state.zipcode,
    });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  hideConfirmationModalCourse = () => {
    this.setState({ showConfirmationModalCancel: false });
  };

  handleAddPause = () => {
    this.setState({ updatePauseLoading: true });
    const pausedMonths = { ...this.state.pausedMonths };
    pausedMonths[makeid(32)] = {
      from: this.state.startMonthNum,
      to: this.state.endMonthNum,
    };
    fetchRetry(
      "updateCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
        pausedMonths,
      },
      1,
      5
    )
      .then(this.updatePauseSuccess)
      .catch(this.handleAddPauseError);
  };

  removePausedMonths = (pausedMonthKey: string) => {
    this.setState({ updatePauseLoading: true });
    const pausedMonths = this.state.pausedMonths;
    pausedMonths[pausedMonthKey] = null;
    fetchRetry(
      "updateCourseBooking",
      {
        courseBookingId: this.state.courseBookingId,
        pausedMonths,
      },
      1,
      5
    )
      .then(this.updatePauseSuccess)
      .catch(this.handleAddPauseError);
  };

  handleAddPauseError = (err: any) => {
    this.setState({ updatePauseLoading: false });
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
    this.handleError(err);
  };

  removePauseSuccess = ({ data }: any) => {
    if (data.success) {
      this.requestCourseBooking();
    } else {
      this.context.createInfo(data.errorMsgDe, "error", 4);
    }
  };

  updatePauseSuccess = ({ data }: any) => {
    this.setState({ updatePauseLoading: false });
    if (data.success) {
      this.requestCourseBooking();
    } else {
      this.context.createInfo(data.errorMsgDe, "error", 4);
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCancellateSubscriptionMonth = (cancelMonth: number) => {
    this.setState({
      showConfirmationModalCancel: true,
      cancelMonth,
    });
  };

  handleMonthCancelConfirmed = () => {
    this.setState({
      cancellationLoading: true,
      showConfirmationModalCancel: false,
    });
    fetchRetry(
      "cancelCourseMonthAdmin",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        courseBookingId: this.state.courseBookingId,
        cancelNote: this.state.refundNote,
        cancelMonth: this.state.cancelMonth,
      },
      1,
      5
    )
      .then(this.createCancellationSuccess)
      .catch(this.handleError);
  };

  handleRefundMonth = (month: number) => {
    this.setState({
      showConfirmationModalRefund: true,
      refundMonth: month,
    });
  };

  handleRetryConfirmed = () => {
    this.setState({
      refundLoading: true,
      showConfirmationModalRetry: false,
    });
    fetchRetry(
      "retryCourseMonth",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        courseBookingId: this.state.courseBookingId,
        month: this.state.retryMonth,
      },
      1,
      5
    )
      .then(this.handleRetrySuccess)
      .catch(this.handleError);
  };

  handleRetrySuccess = ({ data }: any) => {
    this.setState({ refundLoading: false });
    if (data.success) {
      this.requestCourseBooking();
      this.setState({ errorMsgCancellation: null });
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  handleRetry = (month: number) => {
    this.setState({
      showConfirmationModalRetry: true,
      retryMonth: month,
    });
  };

  hideConfirmationModalRefund = () => {
    this.setState({ showConfirmationModalRefund: false });
  };

  hideConfirmationModalRetry = () => {
    this.setState({ showConfirmationModalRetry: false });
  };

  handleRefundMonthConfirmed = () => {
    this.setState({
      refundLoading: true,
      showConfirmationModalRefund: false,
    });
    fetchRetry(
      "createCourseMonthRefund",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        courseBookingId: this.state.courseBookingId,
        month: this.state.refundMonth,
      },
      1,
      5
    )
      .then(this.handleRefundSuccess)
      .catch(this.handleError);
  };

  handleRefundSuccess = ({ data }: any) => {
    this.setState({ refundLoading: false });
    if (data.success) {
      this.requestCourseBooking();
      this.setState({ errorMsgCancellation: null });
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded || !this.state.courseBookingLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    const isCustom = this.state.courseBookingData?.method === "manuell";
    const hasAccount =
      !!this.state.courseBookingData?.userId ||
      this.state.branch?.user?.role !== "Owner";
    const bookForFamily = !!this.state.courseBookingData?.bookForFamily;
    const canEdit =
      this.state.branch?.user?.role === "Owner" || this.context.isAdmin;
    const isInfinityCourse =
      this.state.courseBookingData?.billing === "subscription";
    const nextMonth = getMonthNum(new Date()) + 1;
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg="Willst du diese Kursbuchung wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
          handleConfirm={this.handleDeleteCourseBookingConfirm}
        />
        <ConfirmationModal
          show={!isInfinityCourse && this.state.showConfirmationModalCancel}
          handleClose={this.hideConfirmationModalCourse}
          title="Bist du sicher?"
          msg={
            "Willst du diese Kursbuchung wirklich stornieren? Dieser Vorgang kann nicht rückgängig gemacht werden."
          }
          handleConfirm={this.handleCancellateCourseConfirmed}
        />
        <ConfirmationModal
          show={isInfinityCourse && this.state.showConfirmationModalCancel}
          handleClose={this.hideConfirmationModalCourse}
          title="Bist du sicher?"
          msg={`Willst du diese Kursbuchung wirklich kündigen (Letzer Monat: ${monthToDate(
            this.state.cancelMonth
          )})? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleMonthCancelConfirmed}
        />
        <ConfirmationModal
          show={this.state.showConfirmationModalRefund}
          handleClose={this.hideConfirmationModalRefund}
          title="Bist du sicher?"
          msg={`Willst du die Zahlung (Monat: ${monthToDate(
            this.state.refundMonth
          )}) wirklich stornieren?`}
          handleConfirm={this.handleRefundMonthConfirmed}
        />
        <ConfirmationModal
          show={this.state.showConfirmationModalRetry}
          handleClose={this.hideConfirmationModalRetry}
          title="Bist du sicher?"
          msg={`Willst du die Zahlung (Monat: ${monthToDate(
            this.state.retryMonth
          )}) wirklich erneut anstoßen?`}
          handleConfirm={this.handleRetryConfirmed}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text={
                this.state.backLink ? "zurück" : "zurück zur Teilnehmerliste"
              }
              to={
                this.state.backLink ||
                `/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}`
              }
            />

            <TestMode active={this.state.branch.billing?.mode === "test"} />
            <Title title="Buchungsinformationen" />
            <div>
              <table className="booking-information-table">
                <tbody>
                  <tr className="no-border">
                    <td>Status</td>
                    <td>{getBookingStatus(this.state.courseBookingData)}</td>
                  </tr>
                  {this.state.courseBookingData?.subscriptionCancelledAt && (
                    <tr>
                      <td>Gekündigt am</td>
                      <td>
                        {dateToFullStr(
                          firebaseDateToDateObj(
                            this.state.courseBookingData.subscriptionCancelledAt
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Methode</td>
                    <td>
                      <PaymentMethod
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={this.state.courseId}
                        courseBookingId={this.state.courseBookingId}
                        method={this.state.courseBookingData.method}
                        refresh={() => {}}
                        selectedMandateId={
                          this.state.courseBookingData?.mandateId
                        }
                        type="course"
                      />
                    </td>
                  </tr>
                  {this.state.courseBookingData.price !== null && (
                    <tr>
                      <td>{isInfinityCourse ? "Preis pro Monat" : "Preis"}</td>
                      <td>
                        {priceToStr(
                          isInfinityCourse
                            ? this.state.courseBookingData.montlyPrice
                            : this.state.courseBookingData.price
                        )}
                        €
                      </td>
                    </tr>
                  )}
                  {this.state.courseBookingData.bookerId && (
                    <tr>
                      <td>Eingetragen von</td>
                      <td>{`${this.state.courseBookingData.booker?.fName} ${this.state.courseBookingData.booker?.lName}`}</td>
                    </tr>
                  )}
                  {!isInfinityCourse && (
                    <tr>
                      <td>Bezahl Status</td>
                      <td>
                        <div className="flex">
                          <PaymentStatus
                            type={this.state.courseBookingData.paymentStatus}
                            paid={this.state.paid}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Buchungszeitpunkt</td>
                    <td>
                      {dateToSmallStr(
                        firebaseDateToDateObj(
                          this.state.courseBookingData.created_at
                        )
                      )}
                    </td>
                  </tr>
                  {!isInfinityCourse && (
                    <>
                      {this.state.courseBookingData.refunded ? (
                        <>
                          <tr>
                            <td>Stornierungs Status</td>
                            <td>
                              <div className="flex">
                                <PaymentStatus
                                  type={
                                    this.state.courseBookingData.refundStatus
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Stornierungszeitpunkt</td>
                            <td>
                              {dateToSmallStr(
                                firebaseDateToDateObj(
                                  this.state.courseBookingData.refundCreatedAt
                                )
                              )}
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {this.state.courseBookingData.cancellationDays !=
                            null && (
                            <>
                              <tr>
                                <td>
                                  {isInfinityCourse
                                    ? "Kündigungsfrist"
                                    : "Stornierungsfrist"}
                                </td>
                                <td>
                                  {" bis "}
                                  {dateToFullStr(
                                    firebaseDateToDateObj(
                                      this.state.courseBookingData
                                        .cancellationDate
                                    )
                                  )}
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <tr>
                    <td>Konto</td>
                    <td>
                      {this.state.courseBookingData.userId ? (
                        <Link
                          to={`/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${this.state.userId}?back=/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}/participant/${this.state.courseBookingId}`}
                        >
                          Registriert
                        </Link>
                      ) : (
                        "Gast"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Veranstaltungstermin</td>
                    <td>{this.state.courseDateStr}</td>
                  </tr>
                </tbody>
              </table>
              {isInfinityCourse && (
                <>
                  <SubTitle title="Zahlungsstatus" />
                  <ErrorMessage message={this.state.errorMsgCancellation} />
                  <Input
                    value={this.state.refundNote}
                    onChange={this.handleRefundNoteChange}
                    placeholder="Kündigungsnachricht"
                  />
                  <table className="course-booking-information-table">
                    <thead>
                      <tr>
                        <th>Monat</th>
                        <th>Zahlungsstatus</th>
                        <th>Preis</th>
                        {canEdit && (
                          <th>
                            <div className="table-loading-container">
                              <ClipLoader
                                color={"gray"}
                                size={20}
                                loading={
                                  this.state.cancellationLoading ||
                                  this.state.refundLoading
                                }
                              />
                            </div>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.paymentArr.map((currPayment: any) => (
                        <tr>
                          <td>{currPayment.monthStr}</td>
                          <td>
                            <div>
                              <PaymentStatus type={currPayment.paymentStatus} />
                              {currPayment.refunded && (
                                <div className="margin-left">
                                  <PaymentStatus
                                    type={currPayment.refundStatus}
                                  />
                                </div>
                              )}
                              {currPayment.hasChargebacks && (
                                <div className="margin-left">
                                  <PaymentStatus type={"hasChargebacks"} />
                                </div>
                              )}
                            </div>
                          </td>
                          <td>{currPayment.priceStr}</td>
                          {canEdit && (
                            <td className="course-booking-information-btn">
                              {currPayment.monthNum >= nextMonth ? (
                                (!this.state.courseBookingData
                                  .subscriptionCancelled ||
                                  this.state.courseBookingData.endMonth >=
                                    currPayment.monthNum) && (
                                  <SecButton
                                    className="coursebooking-btn-container cancel-coursebooking-container"
                                    onClick={() => {
                                      this.handleCancellateSubscriptionMonth(
                                        currPayment.monthNum - 1
                                      );
                                    }}
                                    title="Buchung kündigen"
                                  />
                                )
                              ) : (
                                <>
                                  {!(isCustom && isInfinityCourse) && (
                                    <>
                                      {molliePaymentStatus[
                                        currPayment.paymentStatus
                                      ].retryable ? (
                                        <SecButton
                                          className="coursebooking-btn-container retry-coursebooking-container"
                                          onClick={() => {
                                            this.handleRetry(
                                              currPayment.monthNum
                                            );
                                          }}
                                          title="Erneut anstoßen"
                                        />
                                      ) : (
                                        <SecButton
                                          className="coursebooking-btn-container refund-coursebooking-container"
                                          onClick={() => {
                                            this.handleRefundMonth(
                                              currPayment.monthNum
                                            );
                                          }}
                                          title="Zahlung stornieren"
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <SubTitle title="Pausierungen" />
                  <table className="course-booking-information-table pause-table">
                    <thead>
                      <tr>
                        {canEdit && <th>Löschen</th>}
                        <th colSpan={4} style={{ width: "100%" }}>
                          Zeitraum
                        </th>
                        {canEdit && (
                          <th>
                            <div className="table-loading-container">
                              <ClipLoader
                                color={"gray"}
                                size={20}
                                loading={this.state.updatePauseLoading}
                              />
                            </div>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(this.state.pausedMonths).map(
                        (pauseKey: any) => (
                          <>
                            {this.state.pausedMonths[pauseKey] && (
                              <tr>
                                {canEdit && (
                                  <td>
                                    <div
                                      onClick={() => {
                                        this.removePausedMonths(pauseKey);
                                      }}
                                      className="pointer"
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        size="lg"
                                      />
                                    </div>
                                  </td>
                                )}
                                <td>von </td>
                                <td>
                                  {monthToDate(
                                    this.state.pausedMonths[pauseKey].from
                                  )}
                                </td>
                                <td>bis </td>
                                <td colSpan={2}>
                                  {monthToDate(
                                    this.state.pausedMonths[pauseKey].to
                                  )}
                                </td>
                              </tr>
                            )}
                          </>
                        )
                      )}
                      {canEdit && (
                        <tr>
                          <td></td>
                          <td>von</td>
                          <td>
                            <MonthSelector
                              minMonthNum={nextMonth}
                              maxMonthNum={nextMonth + 12}
                              value={this.state.startMonthNum}
                              onSelect={(monthNum: number) => {
                                this.setState({ startMonthNum: monthNum });
                                if (monthNum > this.state.endMonthNum) {
                                  this.setState({ endMonthNum: monthNum });
                                }
                              }}
                            />
                          </td>
                          <td>bis</td>
                          <td>
                            <MonthSelector
                              minMonthNum={this.state.startMonthNum}
                              maxMonthNum={nextMonth + 12}
                              value={this.state.endMonthNum}
                              onSelect={(monthNum: number) => {
                                this.setState({ endMonthNum: monthNum });
                              }}
                            />
                          </td>
                          {canEdit && (
                            <td>
                              <SecButton
                                className="pause-course-container"
                                onClick={this.handleAddPause}
                                title="Kursbuchung pausieren"
                              />
                            </td>
                          )}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              {!isCustom && canEdit && !isInfinityCourse && (
                <>
                  <div className="cancellationContainer">
                    <ErrorMessage message={this.state.errorMsgCancellation} />
                    <Input
                      value={this.state.refundNote}
                      onChange={this.handleRefundNoteChange}
                      placeholder={
                        isInfinityCourse
                          ? "Kündigungsnachricht"
                          : "Stornierungsnachricht"
                      }
                    />
                    <SecButton
                      change={!this.state.courseBookingData.refunded}
                      color="red"
                      loading={this.state.cancellationLoading}
                      onClick={this.handleCancellateCourse}
                      title={
                        isInfinityCourse
                          ? "Kursbuchung kündigen"
                          : "Kursbuchung stornieren"
                      }
                    />
                  </div>
                </>
              )}
              {isCustom && canEdit && !isInfinityCourse && (
                <>
                  <SubTitle title="Notiz" />
                  <Input
                    value={this.state.note}
                    onChange={this.handleNoteChange}
                    placeholder="Notiz"
                  />
                  <SwitchSetting
                    value={this.state.paid}
                    name="Bezahlt"
                    onChange={this.togglePaid}
                  />
                  <ErrorMessage message={this.state.errorMessage} />
                  <SecButton
                    change={this.state.change}
                    color="green"
                    loading={this.state.updateLoading}
                    onClick={this.updateCourseBooking}
                    title="Speichern"
                    className="btn save-courseBooking-btn"
                  />
                  <ErrorMessage message={this.state.errorMessageDelete} />
                  <SecButton
                    change={true}
                    color="red"
                    loading={this.state.deleteLoading}
                    onClick={this.handleDeleteCourseBooking}
                    title="Buchung löschen"
                    className="delete-courseBooking-btn"
                  />
                </>
              )}
            </div>
            <Title title="Teilnehmer Informationen" />
            <div>
              {bookForFamily && (
                <>
                  <SubTitle title="Name (Teilnehmer)" />
                  <div className="course-time-input-container">
                    <Input
                      value={this.state.courseBookingData?.familyUser?.fName}
                      onChange={() => {}}
                      placeholder="Vorname"
                      disabled={hasAccount}
                    />
                    <Input
                      value={this.state.courseBookingData?.familyUser?.lName}
                      onChange={() => {}}
                      placeholder="Nachname"
                      disabled={hasAccount}
                    />
                  </div>
                </>
              )}
              <SubTitle title={bookForFamily ? "Name (gebucht von)" : "Name"} />
              <div className="course-time-input-container">
                <Input
                  value={this.state.fName}
                  onChange={this.handleFNameChange}
                  placeholder="Vorname"
                  disabled={hasAccount}
                />
                <Input
                  value={this.state.lName}
                  onChange={this.handleLNameChange}
                  placeholder="Nachname"
                  disabled={hasAccount}
                />
              </div>
              <SubTitle title={`E-Mail ${isCustom ? "(optional)" : ""}`} />
              <Input
                value={this.state.email}
                onChange={this.handleEmailChange}
                placeholder="E-Mail"
                disabled={hasAccount}
              />
              <SubTitle title={`Adresse ${isCustom ? "(optional)" : ""}`} />
              <div className="course-time-input-container">
                <Input
                  value={this.state.street}
                  onChange={this.handleStreetChange}
                  placeholder="Straße"
                  className="street-input"
                  disabled={hasAccount}
                />
                <Input
                  value={this.state.houseNumber}
                  onChange={this.handleHouseNumberChange}
                  placeholder="Nr."
                  className="house-number-input"
                  disabled={hasAccount}
                />
              </div>
              <div className="profile-location-container">
                <Input
                  value={this.state.zipcode}
                  onChange={this.handleZipcodeChange}
                  placeholder="PLZ"
                  className="zipcode-input"
                  disabled={hasAccount}
                />
                <Input
                  value={this.state.city}
                  onChange={this.handleCityChange}
                  placeholder="Stadt"
                  className="city-input"
                  disabled={hasAccount}
                />
              </div>
              <SubTitle
                title={`Telefonnummer ${isCustom ? "(optional)" : ""}`}
              />
              <Input
                value={this.state.phoneNumber}
                onChange={this.handlePhoneNumberChange}
                placeholder="Telefonnummer"
                disabled={hasAccount}
              />
              <SubTitle
                title={`Geburtsdatum ${isCustom ? "(optional)" : ""}`}
              />
              <input
                type="date"
                className="input-container"
                value={this.state.birthDate}
                onChange={this.handleBirthDateChange}
                disabled={hasAccount}
              />

              {!hasAccount && isCustom && canEdit && (
                <>
                  <ErrorMessage message={this.state.errorMessageUserData} />
                  <SecButton
                    change={this.state.changeUserData}
                    color="green"
                    loading={this.state.updateLoadingUserData}
                    onClick={this.updateCourseBookingUserData}
                    title="Speichern"
                    className="btn save-courseBooking-btn"
                  />
                </>
              )}
            </div>
            {this.state.courseBookingData.formData &&
              this.state.courseBookingData.formData.length > 0 && (
                <>
                  <Title title="Formular" />
                  <div>
                    {this.state.courseBookingData.formData.map((fd: any) => (
                      <>
                        <SubTitle title={fd.label || "-"} />
                        <Input
                          value={`${
                            fd.useIsActive ? (fd.isActive ? "Ja" : "Nein") : ""
                          }${fd.useIsActive && fd.value ? ": " : ""}${
                            fd.value
                          }`}
                          onChange={() => {}}
                          placeholder=""
                          disabled={true}
                        />
                      </>
                    ))}
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  }
}
