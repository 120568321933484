import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import {
  dateDurationToFullStr,
  firebaseDateToDateObj,
} from "../functions/utils";
import "./CourseElement.css";
import { formatLineBreaks } from "../functions/formatHTML";

export default class SingleCourseElementOwn extends React.Component<
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseBooking: any;
    title: string;
    description: string;
    startTime: any;
    endTime: any;
    bookingCreated: string;
    where: string;
    freePlaces: number;
    price: string;
    imgUrl: string;
    inPast: boolean;
    notice: null | string;
    status: string;
    disabled: boolean;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    const courseBookingId = this.props.courseBooking.courseBookingId;
    return (
      <div
        className={`course-container ${
          this.props.disabled || this.props.inPast ? " grayscale" : ""
        }`}
        id={courseBookingId}
      >
        <div>
          {!this.props.imgUrl ? (
            <div className="course-img">
              <FontAwesomeIcon
                icon={faImage}
                size="6x"
                className="image-icon"
              />
            </div>
          ) : (
            <img
              className="course-img"
              src={this.props.imgUrl}
              alt="Kursbild"
            />
          )}
        </div>
        <div className="course-info-container">
          <div className="course-info-inner-container">
            <div className="course-title-container">{this.props.title}</div>
            <div>{this.props.description}</div>
            <div className="course-text-container">
              Status: <span className="normal-weight">{this.props.status}</span>
            </div>
            <div className="course-text-container">
              Wann:{" "}
              <span className="normal-weight">
                {" "}
                {dateDurationToFullStr(
                  firebaseDateToDateObj(this.props.startTime),
                  firebaseDateToDateObj(this.props.endTime)
                )}
              </span>
            </div>
            {this.props.where && (
              <div className="course-text-container">
                Wo: <span className="normal-weight">{this.props.where}</span>
              </div>
            )}
            <div className="course-text-container">
              Gebucht:{" "}
              <span className="normal-weight">{this.props.bookingCreated}</span>
            </div>
            {/* <div className="course-text-container">
                Preis:{" "}
                <span className="normal-weight">{this.props.price}€</span>
              </div> */}
            {this.props.notice && (
              <div className="course-text-container">
                Notiz:{" "}
                <span className="normal-weight">
                  {formatLineBreaks(this.props.notice)}
                </span>
              </div>
            )}
          </div>
          <div>
            <Link
              to={`/club/${this.props.clubId}/branch/${this.props.branchId}/mycourses/${this.props.courseId}/courseBooking/${courseBookingId}`}
              className="course-booking-detail-btn no-underline"
            >
              Details anzeigen
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
