import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./UnpaidBookings.css";
import "../../App.css";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import PaymentStatus from "../../components/PaymentStatus";
import {
  dateTosimpleDateStr,
  firebaseDateToDateObj,
  priceToStr,
} from "../../functions/utils";
import InfoContainer from "../../components/InfoContainer";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Table from "../../components/Table/Table";
import { RowData } from "../../Types";
import MarkAsResolvedBtn from "../../components/markAsResolvedBtn";
import { MainContext } from "../../contexts/MainContext";
import MultiSelector from "../../components/MultiSelector";

type Props = { refreshUnpaidBookings: any };
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class UnpaidBookings extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    paymentConflictsLoaded: boolean;
    updateLoading: boolean;
    showConfirmationModal: boolean;
    selectedCourseBookingId: string;
    unpaidBookingTableArr: Array<RowData>;
    uncancelledBookingTableArr: Array<RowData>;
    activeMemUserHasRoleTableArr: Array<RowData>;
    checkUserHasBookedMemTableArr: Array<RowData>;
    checkUserCourseRoleTableArr: Array<RowData>;
    checkUserMemRoleTableArr: Array<RowData>;
    branchLoaded: boolean;
    branch: any;
    unpaidBookings: any;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      paymentConflictsLoaded: false,
      updateLoading: false,
      showConfirmationModal: false,
      selectedCourseBookingId: "",
      unpaidBookingTableArr: [],
      uncancelledBookingTableArr: [],
      activeMemUserHasRoleTableArr: [],
      checkUserHasBookedMemTableArr: [],
      checkUserCourseRoleTableArr: [],
      checkUserMemRoleTableArr: [],
      branchLoaded: false,
      branch: {},
      unpaidBookings: {},
    };
  }

  componentDidMount = async () => {
    await Promise.all([
      this.requestPaymentConflicts(),
      this.requestBranch(),
    ]).then(this.handleFullyLoaded);
  };

  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  requestPaymentConflicts = async () => {
    await fetchRetry(
      "getPaymentConflictsOfBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleUnpaidNotifications)
      .catch(this.handleError);
  };

  updateCourseBookingBookedButDeletedResolved = (courseBookingId: string) => {
    this.setState({ updateLoading: true });

    fetchRetry(
      "updateCourseBooking",
      {
        courseBookingId: courseBookingId,
        bookedButDeletedResolved: true,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingUpdatedSuccess)
      .catch(this.handleError);
  };

  updateCourseBooking = (courseBookingId: string) => {
    this.setState({ updateLoading: true });

    fetchRetry(
      "updateCourseBooking",
      {
        courseBookingId: courseBookingId,
        canceledAfterBeginResolved: true,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingUpdatedSuccess)
      .catch(this.handleError);
  };

  markAsSolved = (courseBookingId: string) => {
    this.setState({
      showConfirmationModal: true,
      selectedCourseBookingId: courseBookingId,
    });
  };

  markAsSolvedConfirmed = () => {
    const courseBookingId = this.state.selectedCourseBookingId;
    this.setState({
      updateLoading: true,
      showConfirmationModal: false,
    });

    fetchRetry(
      "updateCourseBooking",
      {
        courseBookingId: courseBookingId,
        bookedButDeletedResolved: true,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingUpdatedSuccess)
      .catch(this.handleError);
  };

  requestCoursesBookingUpdatedSuccess = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestPaymentConflicts();
      this.props.refreshUnpaidBookings();
    }
  };

  handleUnpaidNotifications = ({ data }: any) => {
    if (data.success) {
      this.setState(
        {
          unpaidBookings: data,
        },
        this.handleFullyLoaded
      );
    }
  };

  getRoles = (booking: any) => {
    const userRoles = booking.errorRoles || [];
    const branchRoles = this.state.branch.roles;
    return userRoles
      .filter((roleId: string) => branchRoles[roleId])
      .map((roleId: string) => {
        return {
          id: roleId,
          name: branchRoles[roleId].name,
          selected: true,
          color: branchRoles[roleId].type === "club" ? "#5c67ee" : "#ee5c5c",
          notEditable: branchRoles[roleId].type === "club",
        };
      })
      .sort((a: any, b: any) => (a.name < b.name ? 1 : -1));
  };

  handleFullyLoaded = () => {
    const unpaidBookingTableArr =
      this.state.unpaidBookings.unpaidBookingArr.map((booking: any) => {
        return {
          cells: [
            {
              value: booking.userId ? "Konto" : "Gast",
              sort: booking.userId ? "Konto" : "Gast",
            },
            {
              value: booking.user.fName,
              sort: booking.user.fName,
            },
            {
              value: booking.user.lName,
              sort: booking.user.lName,
            },
            {
              value: booking.user.email,
              sort: booking.user.email,
            },
            {
              value: booking.method,
              sort: booking.method,
            },
            {
              value: (
                <PaymentStatus
                  type={booking.paymentStatus}
                  paid={booking.paid}
                />
              ),
              sort: booking.paymentStatus,
            },
            {
              value: `${priceToStr(booking.price)}€`,
              sort: booking.price,
            },
            {
              value: dateTosimpleDateStr(
                firebaseDateToDateObj(booking.created_at)
              ),
              sort: booking.created_at._seconds,
            },
            {
              value: (
                <MarkAsResolvedBtn
                  onClick={() => {
                    this.updateCourseBooking(booking.courseBookingId);
                  }}
                />
              ),
            },
          ],
          onClick: `/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${booking.courseId}/participant/${booking.courseBookingId}`,
        };
      });
    const uncancelledBookingTableArr =
      this.state.unpaidBookings.uncancelledBookingArr.map((booking: any) => {
        return {
          cells: [
            {
              value: booking.userId ? "Konto" : "Gast",
              sort: booking.userId ? "Konto" : "Gast",
            },
            {
              value: booking.user.fName,
              sort: booking.user.fName,
            },
            {
              value: booking.user.lName,
              sort: booking.user.lName,
            },
            {
              value: booking.user.email,
              sort: booking.user.email,
            },
            {
              value: booking.method,
              sort: booking.method,
            },
            {
              value: (
                <PaymentStatus
                  type={booking.paymentStatus}
                  paid={booking.paid}
                />
              ),
              sort: booking.paymentStatus,
            },
            {
              value: `${priceToStr(booking.price)}€`,
              sort: booking.price,
            },
            {
              value: dateTosimpleDateStr(
                firebaseDateToDateObj(booking.created_at)
              ),
              sort: booking.created_at._seconds,
            },
            {
              value: (
                <MarkAsResolvedBtn
                  onClick={() => {
                    this.updateCourseBookingBookedButDeletedResolved(
                      booking.courseBookingId
                    );
                  }}
                />
              ),
            },
          ],
          onClick: `/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${booking.courseId}/participant/${booking.courseBookingId}`,
        };
      });
    const activeMemUserHasRoleTableArr =
      this.state.unpaidBookings.scannedWarnings
        .filter((warning: any) => warning.type === "has-not-role")
        .map((booking: any) => {
          const roles = this.getRoles(booking);
          return {
            cells: [
              {
                value: booking.user.fName,
                sort: booking.user.fName,
              },
              {
                value: booking.user.lName,
                sort: booking.user.lName,
              },
              {
                value: booking.user.email,
                sort: booking.user.email,
              },
              {
                value: (
                  <MultiSelector
                    viewOnly
                    onSelect={() => {}}
                    onUnselect={() => {}}
                    arr={roles}
                  />
                ),
              },
            ],
            onClick: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${booking.userId}`,
          };
        });
    const checkUserHasBookedMemTableArr =
      this.state.unpaidBookings.scannedWarnings
        .filter((warning: any) => warning.type === "has-role")
        .map((booking: any) => {
          const roles = this.getRoles(booking);
          return {
            cells: [
              {
                value: booking.user.fName,
                sort: booking.user.fName,
              },
              {
                value: booking.user.lName,
                sort: booking.user.lName,
              },
              {
                value: booking.user.email,
                sort: booking.user.email,
              },
              {
                value: (
                  <MultiSelector
                    viewOnly
                    onSelect={() => {}}
                    onUnselect={() => {}}
                    arr={roles}
                  />
                ),
              },
            ],
            onClick: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${booking.userId}`,
          };
        });
    const checkUserCourseRoleTableArr =
      this.state.unpaidBookings.scannedWarnings
        .filter((warning: any) => warning.type === "has-not-course-role")
        .map((booking: any) => {
          const roles = this.getRoles(booking);
          return {
            cells: [
              {
                value: booking.user.fName,
                sort: booking.user.fName,
              },
              {
                value: booking.user.lName,
                sort: booking.user.lName,
              },
              {
                value: booking.user.email,
                sort: booking.user.email,
              },
              {
                value: (
                  <MultiSelector
                    viewOnly
                    onSelect={() => {}}
                    onUnselect={() => {}}
                    arr={roles}
                  />
                ),
              },
            ],
            onClick: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${booking.userId}`,
          };
        });
    const checkUserMemRoleTableArr = this.state.unpaidBookings.scannedWarnings
      .filter((warning: any) => warning.type === "has-not-membership-role")
      .map((booking: any) => {
        const roles = this.getRoles(booking);
        return {
          cells: [
            {
              value: booking.user.fName,
              sort: booking.user.fName,
            },
            {
              value: booking.user.lName,
              sort: booking.user.lName,
            },
            {
              value: booking.user.email,
              sort: booking.user.email,
            },
            {
              value: (
                <MultiSelector
                  viewOnly
                  onSelect={() => {}}
                  onUnselect={() => {}}
                  arr={roles}
                />
              ),
            },
          ],
          onClick: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${booking.userId}`,
        };
      });

    this.setState({
      unpaidBookingTableArr,
      uncancelledBookingTableArr,
      activeMemUserHasRoleTableArr,
      checkUserHasBookedMemTableArr,
      checkUserCourseRoleTableArr,
      checkUserMemRoleTableArr,
      paymentConflictsLoaded: true,
    });
  };

  handleRedirect = (redirect: string) => {
    this.props.history.push({
      pathname: redirect,
    });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  render() {
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg="Der Kunde hat einen Kurs bezahlt und dieser wurde gelöscht. Willst du diese Buchung wirklich als gelöst markieren? Dieser Vorgang kann nicht rückgängig gemacht werden."
          handleConfirm={this.markAsSolvedConfirmed}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Unbezahlte Buchungen" />
            <InfoContainer>
              Unbezahlte Buchungen können entstehen, wenn die Bezahlung einer
              Kursbuchung nach Kursbeginn abgebrochen wird oder fehlschlägt.
            </InfoContainer>
            <Table
              header={[
                { title: "Konto", displayWidth: 1000 },
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "E-Mail", displayWidth: 1400 },
                { title: "Method", displayWidth: 950 },
                { title: "Status", displayWidth: 800 },
                { title: "Betrag", displayWidth: 600 },
                { title: "Datum", displayWidth: 700 },
                { title: "", disableSort: true },
              ]}
              data={this.state.unpaidBookingTableArr}
              loaded={this.state.paymentConflictsLoaded}
              skeletonRows={3}
              onClick={this.handleRedirect}
              defaultSortDirection="asc"
              defaultSortIndex={2}
              counter
            />
            <div className="large-space"></div>
            <Title title="Nicht stornierte Buchungen" />
            <InfoContainer>
              Nicht stornierte Buchungen können entstehen, wenn ein Kurs
              gelöscht wurde und die Buchung noch nicht storniert wurde.
            </InfoContainer>
            <Table
              header={[
                { title: "Konto", displayWidth: 1000 },
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "E-Mail", displayWidth: 1400 },
                { title: "Method", displayWidth: 950 },
                { title: "Status", displayWidth: 800 },
                { title: "Betrag", displayWidth: 600 },
                { title: "Datum", displayWidth: 700 },
                { title: "", disableSort: true },
              ]}
              data={this.state.uncancelledBookingTableArr}
              loaded={this.state.paymentConflictsLoaded}
              skeletonRows={3}
              onClick={this.handleRedirect}
              defaultSortDirection="asc"
              defaultSortIndex={2}
              counter
            />
            <div className="large-space"></div>
            <Title title="Hat Mitgliedschaftsrolle" />
            <InfoContainer>
              Benutzer haben die bei der Mitgliedschaftsbuchung automatisch
              zugeteilte Rolle noch.
            </InfoContainer>
            <Table
              header={[
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "E-Mail", displayWidth: 1400 },
                { title: "Rollen" },
              ]}
              data={this.state.activeMemUserHasRoleTableArr}
              loaded={this.state.paymentConflictsLoaded}
              skeletonRows={3}
              onClick={this.handleRedirect}
              defaultSortDirection="asc"
              defaultSortIndex={2}
              counter
            />
            <div className="large-space"></div>
            <Title title="Hat Mitgliedschaft gebucht" />
            <InfoContainer>
              Benutzer mit einer Rolle, welche man über eine Mitgliedschaft
              bekommen kann, hat die Mitgliedschaft auch gebucht.
            </InfoContainer>
            <Table
              header={[
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "E-Mail", displayWidth: 1400 },
                { title: "Rollen" },
              ]}
              data={this.state.checkUserHasBookedMemTableArr}
              loaded={this.state.paymentConflictsLoaded}
              skeletonRows={3}
              onClick={this.handleRedirect}
              defaultSortDirection="asc"
              defaultSortIndex={2}
              counter
            />
            <div className="large-space"></div>
            <Title title="Hat erforderliche Rolle (Kurs)" />
            <InfoContainer>
              Benutzer haben noch die erforderliche Rolle, um einen speziellen
              Kurs zu buchen.
            </InfoContainer>
            <Table
              header={[
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "E-Mail", displayWidth: 1400 },
                { title: "Rollen" },
              ]}
              data={this.state.checkUserCourseRoleTableArr}
              loaded={this.state.paymentConflictsLoaded}
              skeletonRows={3}
              onClick={this.handleRedirect}
              defaultSortDirection="asc"
              defaultSortIndex={2}
              counter
            />
            <div className="large-space"></div>
            <Title title="Hat erforderliche Rolle (Mitgliedschaft)" />
            <InfoContainer>
              Benutzer haben noch die erforderliche Rolle, um eine speziellen
              Mitgliedschaft zu buchen.
            </InfoContainer>
            <Table
              header={[
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "E-Mail", displayWidth: 1400 },
                { title: "Rollen" },
              ]}
              data={this.state.checkUserMemRoleTableArr}
              loaded={this.state.paymentConflictsLoaded}
              skeletonRows={3}
              onClick={this.handleRedirect}
              defaultSortDirection="asc"
              defaultSortIndex={2}
              counter
            />
          </div>
        </div>
      </>
    );
  }
}
