import React from "react";
import "./MyCourses.css";
import "../App.css";
import {
  dateToFullStr,
  firebaseDateToDateObj,
  inPast,
  priceToStr,
} from "../functions/utils";
import SingleCourseElementOwn from "../components/SingleCourseElementOwn";
import BlockCourseElementOwn from "../components/BlockCourseElementOwn";
import InfinityCourseElementOwn from "../components/InfinityCourseElementOwn";
import { fetchRetry } from "../functions/request";
import Title from "../components/Title";
import CourseElementSkeleton from "../components/CourseElementSkeleton";
import { MainContext } from "../contexts/MainContext";
import SearchInput from "../components/SearchInput";

export default class MyCourses extends React.Component<
  {},
  {
    coursesLoaded: boolean;
    courseBookings: Array<any>;
    filteredCourseBookings: Array<any>;
    showCreateModal: boolean;
    courseSearch: string;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      coursesLoaded: false,
      courseBookings: [],
      filteredCourseBookings: [],
      showCreateModal: false,
      courseSearch: "",
    };
  }

  componentDidMount = () => {
    this.requestCourses();
  };

  requestCourses = () => {
    fetchRetry(
      "getCoursesOfUserF",
      {
        targetUserId: this.context.selectedUserId,
        limit: 50,
        onlyFuture: false,
      },
      1,
      5
    )
      .then(this.handleCoursesSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCoursesSuccess = ({ data }: any) => {
    this.setState({ coursesLoaded: true });
    if (data.success) {
      const courses = data.data;
      const courseBookings = [];
      for (const course of courses) {
        for (const booking of course.bookingArr) {
          courseBookings.push({
            course: course,
            ...booking,
          });
        }
      }

      courseBookings.sort((a, b) => {
        const getGroup = (item: any): number => {
          if (
            item.active &&
            !item.refunded &&
            item.course.courseType === "infinity-course"
          )
            return 1;
          if (item.course.courseType !== "infinity-course") return 2;
          return 3;
        };

        const aGroup = getGroup(a);
        const bGroup = getGroup(b);

        if (aGroup !== bGroup) {
          return aGroup - bGroup;
        }

        if (aGroup === 2) {
          return b.course.startTime._seconds - a.course.startTime._seconds;
        }

        return 0;
      });

      console.log(
        courseBookings.map((c: any) => [c.active, c.course.courseType])
      );
      this.setState({ courseBookings: courseBookings }, () => {
        this.filterCourseBookings(this.state.courseSearch);
      });
      const highlighedCourseBookingId = window.location.href.slice(
        window.location.href.indexOf("#") + 1
      );
      var element = document.getElementById(highlighedCourseBookingId);
      element?.scrollIntoView();
    }
  };

  handleCloseCreateModal = () => {
    this.setState({ showCreateModal: false });
  };

  handleShowCreateModal = () => {
    this.setState({ showCreateModal: true });
  };

  handleChangeCourseSearch = (val: string) => {
    this.filterCourseBookings(val);
    this.setState({ courseSearch: val });
  };

  filterCourseBookings = (filter: string) => {
    const lowerFilter = filter.toLowerCase();
    const filteredCourses = this.state.courseBookings.filter(
      (courseBooking: any) => {
        return courseBooking.course.title.toLowerCase().includes(lowerFilter);
      }
    );
    this.setState({ filteredCourseBookings: filteredCourses });
  };

  render() {
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Meine Kurse" />
            <SearchInput
              name="course-search-input"
              placeholder="Kurssuche"
              value={this.state.courseSearch}
              onChange={this.handleChangeCourseSearch}
              className="no-margin"
            />
            {!this.state.coursesLoaded ? (
              <>
                <CourseElementSkeleton />
                <CourseElementSkeleton />
                <CourseElementSkeleton />
              </>
            ) : (
              <>
                {this.state.courseBookings.length === 0 && (
                  <div className="no-courses-container">
                    Buche deinen ersten Kurs damit dieser hier angezeigt wird.
                  </div>
                )}
              </>
            )}

            {this.state.filteredCourseBookings.map((courseBooking: any) => (
              <>
                {courseBooking.course.courseType === "single-course" && (
                  <SingleCourseElementOwn
                    clubId={courseBooking.course.clubId}
                    branchId={courseBooking.course.branchId}
                    courseId={courseBooking.course.id}
                    courseBooking={courseBooking}
                    title={courseBooking.course.title}
                    description={courseBooking.course.description}
                    startTime={courseBooking.course.startTime}
                    endTime={courseBooking.course.endTime}
                    bookingCreated={dateToFullStr(
                      firebaseDateToDateObj(courseBooking.created_at)
                    )}
                    where={courseBooking.course.location.name}
                    freePlaces={courseBooking.course.freePlaces}
                    price={priceToStr(courseBooking.course.price)}
                    imgUrl={courseBooking.course.imgLowRes}
                    key={courseBooking.course.id}
                    inPast={inPast(
                      firebaseDateToDateObj(courseBooking.course.startTime)
                    )}
                    notice={courseBooking.course.bookingMailMessage}
                    disabled={courseBooking.refunded || !courseBooking.active}
                    status={
                      courseBooking.active
                        ? "Gebucht"
                        : courseBooking.refunded
                        ? "Storniert"
                        : "Nicht Gebucht"
                    }
                  />
                )}
                {courseBooking.course.courseType === "block-course" && (
                  <BlockCourseElementOwn
                    clubId={courseBooking.course.clubId}
                    branchId={courseBooking.course.branchId}
                    courseId={courseBooking.course.id}
                    courseBooking={courseBooking}
                    title={courseBooking.course.title}
                    description={courseBooking.course.description}
                    when={dateToFullStr(
                      firebaseDateToDateObj(courseBooking.course.startTime)
                    )}
                    bookingCreated={dateToFullStr(
                      firebaseDateToDateObj(courseBooking.created_at)
                    )}
                    courses={courseBooking.course.courses}
                    freePlaces={courseBooking.course.freePlaces}
                    price={priceToStr(courseBooking.course.price)}
                    imgUrl={courseBooking.course.imgLowRes}
                    inPast={inPast(
                      firebaseDateToDateObj(courseBooking.course.startTime)
                    )}
                    notice={courseBooking.course.bookingMailMessage}
                    key={courseBooking.course.id}
                    disabled={courseBooking.refunded || !courseBooking.active}
                    status={
                      courseBooking.active
                        ? "Gebucht"
                        : courseBooking.refunded
                        ? "Storniert"
                        : "Nicht Gebucht"
                    }
                  />
                )}
                {courseBooking.course.courseType === "infinity-course" && (
                  <InfinityCourseElementOwn
                    clubId={courseBooking.course.clubId}
                    branchId={courseBooking.course.branchId}
                    courseId={courseBooking.course.id}
                    courseBooking={courseBooking}
                    title={courseBooking.course.title}
                    description={courseBooking.course.description}
                    time={courseBooking.course.time}
                    duration={courseBooking.course.duration}
                    bookingCreated={dateToFullStr(
                      firebaseDateToDateObj(courseBooking.created_at)
                    )}
                    where={courseBooking.course.location?.name}
                    weekDay={courseBooking.course.weekDay}
                    freePlaces={courseBooking.course.freePlaces}
                    price={priceToStr(courseBooking.course.price)}
                    imgUrl={courseBooking.course.imgLowRes}
                    notice={courseBooking.course.bookingMailMessage}
                    key={courseBooking.course.id}
                    disabled={courseBooking.refunded || !courseBooking.active}
                    status={
                      courseBooking.refunded
                        ? "Storniert"
                        : courseBooking.active
                        ? "Gebucht"
                        : "Nicht Gebucht"
                    }
                  />
                )}
              </>
            ))}
          </div>
        </div>
      </>
    );
  }
}
