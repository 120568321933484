import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./CreateCourse.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import Input from "../../components/Input";
import SubTitle from "../../components/SubTitle";
import MultiSelector from "../../components/MultiSelector";
import ErrorMessage from "../../components/ErrorMessage";
import {
  addMinutesToDate,
  dateToStr,
  dateToTimeStr,
  findWithAttr,
  getCurrentDayStr,
  reformatDate,
  reformatDateReverse,
  strTimeToMin,
  inputStrToDate,
  addDaysToDate,
  copyDate,
  removeTimeZoneOffset,
  stringDateAndTimeToDate,
  strToDateWithMinutes,
  firebaseDateToDateObj,
  dateToStrInput,
} from "../../functions/utils";
import SecButton from "../../components/SecButton";
import DropDown from "../../components/DropDown";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import CourseTypeSelector from "../../components/CourseTypeSelector";
import Title from "../../components/Title";
import Calendar from "../../components/Calendar";
import DateComponent from "../../components/DateComponent";
import {
  courseTypes,
  dateUnits,
  dateUnitsArr,
  weekDays,
} from "../../services/constants";
import Switch from "../../components/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoContainer from "../../components/InfoContainer";
import SubTitleTwo from "../../components/SubTitleTwo";
import { MainContext } from "../../contexts/MainContext";
import TextCheckbox from "../../components/TextCheckbox";
import {
  faChevronDown,
  faChevronUp,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import DateSectionSelector from "../../components/DateSectionSelector";
import TextArea from "../../components/TextArea";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId?: string;
  }>;

const courseTypeName = ["single-course", "block-course", "infinity-course"];

export default class CreateCourse extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    followUpCourseId: string | undefined;
    courseLoaded: boolean;
    branchLoaded: boolean;
    course: any;
    branch: any;
    creatingLoading: boolean;
    courseName: string;
    courseDescription: string;
    duration: number;
    courseDate: string;
    courseTime: string;
    allowOnlinePayment: boolean;
    allowPaymentOnSite: boolean;
    coursePlaces: number;
    courseBookingNote: string;
    roleArr: Array<any>;
    tagArr: Array<any>;
    rolePriceArr: Array<any>;
    errMsg: null | string;
    places: Array<any>;
    selectedLocation: string;
    redirect: null | string;
    courseType: number;
    dates: Array<any>;
    weekDay: string;
    weekDaySection: string;
    dateUnit: string;
    noticePeriod: number;
    infinityStartDate: string;
    infinityEndDate: string;
    hasExpireDate: boolean;
    showDates: boolean;
    showAddRolePricing: boolean;
    searchTextPriceRole: string;
    hasCancellationAllowed: boolean;
    cancellationDays: number;
    negativeDates: Array<any>;
    sectorEndDate: any;
    sectorStartDate: any;
    editSectorErrMsg: string | null;
    selectedDates: Array<string>;
    hasVisibleFromAllowed: boolean;
    visibleDays: number;
    hasBookableFromAllowed: boolean;
    bookableDays: number;
    roleArrLoaded: boolean;
    hasMinAge: boolean;
    hasMaxAge: boolean;
    minAge: number;
    maxAge: number;
    allowBookForFamily: boolean;
    branchRoleArr: Array<string>;
    selectedForm: string;
    forms: any[];
  }
> {
  static contextType = MainContext;
  private wrapperRef: any;
  private calendarRef: any;

  constructor(props: ComposedProps) {
    super(props);

    this.wrapperRef = React.createRef();
    this.calendarRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      followUpCourseId: this.props.match.params.courseId,
      courseLoaded: false,
      branchLoaded: false,
      course: {},
      branch: {},
      creatingLoading: false,
      courseName: "",
      courseDescription: "",
      duration: 60,
      courseDate: getCurrentDayStr(),
      courseTime: "12:00",
      allowOnlinePayment: true,
      allowPaymentOnSite: false,
      coursePlaces: 10,
      courseBookingNote: "",
      roleArr: [],
      tagArr: [],
      rolePriceArr: [],
      errMsg: null,
      places: [],
      selectedLocation: "",
      redirect: null,
      courseType: 1,
      dates: [],
      weekDay: "0",
      weekDaySection: "7",
      dateUnit: "3",
      noticePeriod: 1,
      infinityStartDate: getCurrentDayStr(),
      infinityEndDate: getCurrentDayStr(),
      hasExpireDate: false,
      showDates: false,
      showAddRolePricing: false,
      searchTextPriceRole: "",
      hasCancellationAllowed: false,
      cancellationDays: 7,
      negativeDates: [],
      sectorEndDate: null,
      sectorStartDate: null,
      editSectorErrMsg: null,
      selectedDates: [],
      hasVisibleFromAllowed: false,
      visibleDays: 0,
      hasBookableFromAllowed: false,
      bookableDays: 0,
      roleArrLoaded: false,
      hasMinAge: false,
      hasMaxAge: false,
      minAge: 0,
      maxAge: 0,
      allowBookForFamily: false,
      branchRoleArr: [],
      selectedForm: "",
      forms: [],
    };
  }

  componentDidMount = async () => {
    if (this.state.followUpCourseId) {
      await Promise.all([this.requestBranch(), this.requestCourse()]).then(
        () => {
          this.handleRoleArr();
        }
      );
    } else {
      await Promise.all([this.requestBranch()]).then(() => {
        this.handleRoleArr();
      });
      this.setState({ courseLoaded: true, roleArrLoaded: true });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (this.state.showAddRolePricing) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.hideAddRolePricingWindow();
      }
    }
  }

  hideAddRolePricingWindow = () => {
    this.setState({ showAddRolePricing: false });
  };

  requestCourse = async () => {
    await fetchRetry(
      "getCourseFromIdFNew",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.followUpCourseId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleCourseSuccess)
      .catch(this.handleError);
  };

  handleCourseSuccess = ({ data }: any) => {
    this.setState({ courseLoaded: true });
    if (data.success) {
      const courseData = data.data[0];
      const startTime = firebaseDateToDateObj(courseData.startTime);
      const endTime = firebaseDateToDateObj(courseData?.endTime);
      const duration =
        endTime && startTime
          ? (endTime.getTime() - startTime.getTime()) / 60000
          : 60;

      this.setState({
        course: courseData,
        selectedLocation: courseData?.location?.id
          ? courseData?.location?.id
          : "",
        courseType: courseTypes[courseData.courseType].num,
        courseName: courseData.title,
        courseDescription: courseData.description,
        coursePlaces: courseData.places,
        courseDate: dateToStrInput(startTime),
        courseTime:
          courseData.courseType === "infinity-course"
            ? courseData.time
            : dateToTimeStr(startTime),
        duration:
          courseData.courseType === "infinity-course"
            ? courseData.duration
            : duration && courseData.courseType !== "block-course"
            ? duration
            : 90,
        courseBookingNote: courseData.bookingMailMessage,
        noticePeriod: courseData.noticePeriod,
        dateUnit:
          dateUnitsArr.indexOf(courseData.noticePeriodDateUnit) + 1 + "",
        // time: courseData.time,
        cancellationDays:
          courseData.cancellationDays !== null
            ? courseData.cancellationDays
            : 0,
        hasCancellationAllowed: courseData.cancellationDays !== null,
        visibleDays:
          courseData.visibleDays !== null ? courseData.visibleDays : 0,
        hasVisibleFromAllowed: courseData.visibleDays !== null,
        bookableDays:
          courseData.bookableDays !== null ? courseData.bookableDays : 0,
        hasBookableFromAllowed: courseData.bookableDays !== null,
        negativeDates: courseData.negativeDates
          ? courseData.negativeDates.slice()
          : [],
        weekDay: courseData.weekDay,
        maxAge: courseData.maxAge,
        hasMaxAge: courseData.maxAge !== null,
        minAge: courseData.minAge,
        hasMinAge: courseData.minAge !== null,
        selectedForm: courseData?.form?.id || null,
      });
    }
  };

  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    if (data.success) {
      const branch = data.data;
      const places: Array<any> = [];
      Object.keys(branch.locations).forEach((locationId: string) => {
        places.push({
          id: locationId,
          name: branch?.locations[locationId].name,
        });
      });
      places.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      const tagArr = branch.tags
        ? Object.keys(branch.tags).map((tagId: string) => {
            const currBranchRole = branch.tags[tagId];
            return {
              ...currBranchRole,
              ...{ selected: false, id: tagId, color: "#5DADE2" },
            };
          })
        : [];

      const branchRoleArr = Object.keys(branch.roles)
        .filter((roleId: string) => branch?.roles[roleId].type !== "club")
        .map((roleId: string) => {
          const currBranchRole = branch?.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: false,
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        });

      const roleArr = Object.keys(branch.roles).map((roleId: string) => {
        const currBranchRole = branch.roles[roleId];
        return {
          ...currBranchRole,
          ...{
            selected: false,
            id: roleId,
            color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
          },
        };
      });

      roleArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        selected: false,
        color: "#ffd025",
      });
      roleArr.push({
        custom: false,
        default: false,
        id: "guest",
        name: "Jeder",
        selected: true,
        color: "#ffd025",
      });
      const rolePriceArr = Object.keys(branch.roles).map((roleId: string) => {
        const currBranchRole = branch.roles[roleId];
        return {
          ...currBranchRole,
          ...{
            selected: false,
            id: roleId,
            val: 0,
            showAlways: false,
            color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
          },
        };
      });
      rolePriceArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        selected: false,
        color: "#ffd025",
        val: 0,
        priority: -1,
        showAlways: false,
      });
      rolePriceArr.push({
        custom: false,
        default: true,
        id: "default",
        name: "Basis",
        selected: true,
        color: "#9e9e9e",
        val: 0,
        priority: -3,
        showAlways: false,
      });
      rolePriceArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));

      const forms = Object.keys(branch.forms).map((formId: string) => {
        return {
          id: formId,
          name: branch.forms[formId].name,
        };
      });

      forms.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );

      this.setState({
        branch: branch,
        places,
        roleArr,
        rolePriceArr,
        branchRoleArr,
        tagArr,
        branchLoaded: true,
        forms,
      });
    } else {
      this.setState({ branchLoaded: true });
    }
  };

  handleRoleArr = () => {
    if (
      this.state.branchLoaded &&
      this.state.courseLoaded &&
      this.state.followUpCourseId
    ) {
      const branchRoleArr = Object.keys(this.state.branch.roles)
        .filter(
          (roleId: string) => this.state.branch?.roles[roleId].type !== "club"
        )
        .map((roleId: string) => {
          const currBranchRole = this.state.branch?.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: this.state.course.manageRoles.includes(roleId),
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        });

      const roleArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          const currBranchRole = this.state.branch.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: this.state.course.allowedRoles.includes(roleId),
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        }
      );
      roleArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        color: "#ffd025",
        selected: this.state.course.allowedRoles.includes("public"),
      });
      roleArr.push({
        custom: false,
        default: false,
        id: "guest",
        name: "Jeder",
        selected: this.state.course.allowedRoles.includes("guest"),
        color: "#ffd025",
      });

      const rolePriceArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          const currBranchRole = this.state.branch.roles[roleId];
          const index = this.state.course.priceArr.findIndex(
            (role: any) => role.id === roleId
          );
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: index !== -1,
              val:
                index !== -1
                  ? this.state.course.priceArr[index].price / 100
                  : 0,
              showAlways:
                index !== -1
                  ? !!this.state.course.priceArr[index].showAlways
                  : false,
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        }
      );
      const indexPublic = this.state.course.priceArr.findIndex(
        (role: any) => role.id === "public"
      );
      rolePriceArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        selected: indexPublic !== -1,
        color: "#ffd025",
        val:
          indexPublic !== -1
            ? this.state.course.priceArr[indexPublic].price / 100
            : 0,
        priority: -1,
        showAlways:
          indexPublic !== -1
            ? !!this.state.course.priceArr[indexPublic].showAlways
            : false,
      });

      const indexDefault = this.state.course.priceArr.findIndex(
        (role: any) => role.id === "default"
      );
      rolePriceArr.push({
        custom: false,
        default: true,
        id: "default",
        name: "Basis",
        selected: indexDefault !== -1,
        color: "#9e9e9e",
        val:
          indexDefault !== -1
            ? this.state.course.priceArr[indexDefault].price / 100
            : 0,
        priority: -3,
        showAlways:
          indexDefault !== -1
            ? !!this.state.course.priceArr[indexDefault].showAlways
            : false,
      });
      rolePriceArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));

      const tagArr = this.state.branch.tags
        ? Object.keys(this.state.branch.tags).map((tagId: string) => {
            const currBranchRole = this.state.branch.tags[tagId];
            return {
              ...currBranchRole,
              ...{
                selected: this.state.course.tags.includes(tagId),
                id: tagId,
                color: "#5DADE2",
              },
            };
          })
        : [];

      this.setState({
        tagArr,
        roleArr,
        branchRoleArr,
        rolePriceArr,
        roleArrLoaded: true,
      });
    }
  };

  toggleCancellationAllowed = () => {
    this.setState({
      hasCancellationAllowed: !this.state.hasCancellationAllowed,
    });
  };

  toggleVisibleFromAllowed = () => {
    this.setState({
      hasVisibleFromAllowed: !this.state.hasVisibleFromAllowed,
    });
  };

  toggleBookableFromAllowed = () => {
    this.setState({
      hasBookableFromAllowed: !this.state.hasBookableFromAllowed,
    });
  };

  handleChangeSearchTextPriceRole = (val: any) => {
    this.setState({ searchTextPriceRole: val.target.value });
  };

  handleNameChange = (val: any) => {
    this.setState({ courseName: val });
  };

  handleDescriptionChange = (val: any) => {
    this.setState({ courseDescription: val });
  };

  handleDurationChange = (val: any) => {
    this.setState({ duration: val });
  };

  handleCancellationDaysChange = (val: any) => {
    this.setState({ cancellationDays: val });
  };

  handleVisibleFromDaysChange = (val: any) => {
    this.setState({ visibleDays: val });
  };

  handleBookableFromChange = (val: any) => {
    this.setState({ bookableDays: val });
  };

  handleNoticePeriodChange = (val: any) => {
    this.setState({ noticePeriod: val });
  };

  handleDateChange = (val: any) => {
    this.setState({ courseDate: val.target.value });
  };

  handleTimeChange = (val: any) => {
    this.setState({ courseTime: val.target.value });
  };

  handlePriceRoleChange = (id: number, val: any) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].val = Math.floor(val * 100) / 100;
    this.setState({ rolePriceArr });
  };

  handleShowAlwaysChange = (id: number) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].showAlways = !rolePriceArr[index].showAlways;
    this.setState({ rolePriceArr });
  };

  handleLocationChange = (val: any) => {
    this.setState({ selectedLocation: val });
  };

  handleWeekDayChange = (val: any) => {
    this.setState({ weekDay: val });
  };

  handleDateUnitsChange = (val: any) => {
    this.setState({ dateUnit: val });
  };

  handleChangeAllowOnlinePayment = () => {
    this.setState({ allowOnlinePayment: !this.state.allowOnlinePayment });
  };

  handleChangeAllowPaymentOnSite = () => {
    this.setState({ allowPaymentOnSite: !this.state.allowPaymentOnSite });
  };

  handleCoursePlacesChange = (val: any) => {
    this.setState({ coursePlaces: val });
  };

  handleBookingNoteChange = (val: any) => {
    this.setState({ courseBookingNote: val });
  };

  handleRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ roleArr: newRoleArr });
  };

  handleRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ roleArr: newRoleArr });
  };

  handleTagSelect = (bookingTypeId: string) => {
    const newTagArr: any = this.state.tagArr;
    const index = findWithAttr(newTagArr, "id", bookingTypeId);
    newTagArr[index].selected = true;
    this.setState({ tagArr: newTagArr });
  };

  handleTagUnselect = (bookingTypeId: string) => {
    const newTagArr: any = this.state.tagArr;
    const index = findWithAttr(newTagArr, "id", bookingTypeId);
    newTagArr[index].selected = false;
    this.setState({ tagArr: newTagArr });
  };

  handleSectorStartDateChange = (date: Date) => {
    if (!this.state.sectorEndDate || date > this.state.sectorEndDate) {
      this.setState({
        sectorEndDate: date,
      });
    }
    this.setState({ sectorStartDate: date });
  };

  handleSectorEndDateChange = (date: Date) => {
    this.setState({ sectorEndDate: date });
  };

  handleCreateCourse = () => {
    // get all role IdS
    this.setState({ creatingLoading: true });
    const roleIds: Array<string> = this.state.roleArr
      .filter((item: any) => item.selected)
      .map((item: any) => item.id);
    const tagIds: Array<string> = this.state.tagArr
      .filter((item: any) => item.selected)
      .map((item: any) => item.id);
    const priceArr = this.state.rolePriceArr
      .filter((rolePrice: any) => rolePrice.selected)
      .map((rolePrice: any) => {
        return {
          id: rolePrice.id,
          price: rolePrice.val * 100,
          showAlways: rolePrice.showAlways,
        };
      });
    const manageRoleIds: Array<string> = this.state.branchRoleArr
      .filter((item: any) => item.selected)
      .map((item: any) => item.id);
    if (this.state.courseType === 1) {
      const courses = this.state.dates.map((date: any) => {
        const startDateObj = removeTimeZoneOffset(
          stringDateAndTimeToDate(date.dateStr, date.time)
        );
        const endDateObj = removeTimeZoneOffset(
          stringDateAndTimeToDate(date.endDateStr, date.endTime)
        );
        return {
          title: this.state.courseName,
          description: this.state.courseDescription,
          date: dateToStr(startDateObj),
          time: dateToTimeStr(startDateObj),
          endDate: dateToStr(endDateObj),
          endTime: dateToTimeStr(endDateObj),
          locationId: date.location ? date.location : null,
          places: this.state.coursePlaces,
          allowedRoles: roleIds,
          tags: tagIds,
          bookingMailMessage: this.state.courseBookingNote,
        };
      });

      fetchRetry(
        "createCourse",
        {
          clubId: this.state.clubId,
          branchId: this.state.branchId,
          followUpCourseId: this.state.followUpCourseId,
          courseType: courseTypeName[0],
          courses: courses,
          priceArr,
          cancellationDays: this.state.hasCancellationAllowed
            ? this.state.cancellationDays
            : null,
          visibleDays: this.state.hasVisibleFromAllowed
            ? this.state.visibleDays
            : null,
          bookableDays: this.state.hasBookableFromAllowed
            ? this.state.bookableDays
            : null,
          minAge: this.state.hasMinAge ? this.state.minAge : null,
          maxAge: this.state.hasMaxAge ? this.state.maxAge : null,
          allowBookForFamily: this.state.allowBookForFamily,
          manageRoles: manageRoleIds,
          formId: this.state.selectedForm || null,
        },
        1,
        5
      )
        .then(this.handleSuccessCreatingCours)
        .catch(this.handleErrorCreatingCourse);
    } else if (this.state.courseType === 2) {
      const courses = this.state.dates.map((date: any) => {
        const startDateObj = removeTimeZoneOffset(
          stringDateAndTimeToDate(date.dateStr, date.time)
        );
        const endDateObj = removeTimeZoneOffset(
          stringDateAndTimeToDate(date.endDateStr, date.endTime)
        );
        return {
          title: this.state.courseName,
          description: this.state.courseDescription,
          date: dateToStr(startDateObj),
          time: dateToTimeStr(startDateObj),
          endDate: dateToStr(endDateObj),
          endTime: dateToTimeStr(endDateObj),
          locationId: date.location ? date.location : null,
        };
      });
      fetchRetry(
        "createCourse",
        {
          clubId: this.state.clubId,
          branchId: this.state.branchId,
          followUpCourseId: this.state.followUpCourseId,
          courseType: courseTypeName[1],
          courses: courses,
          title: this.state.courseName,
          description: this.state.courseDescription,
          allowedRoles: roleIds,
          tags: tagIds,
          places: this.state.coursePlaces,
          priceArr,
          bookingMailMessage: this.state.courseBookingNote,
          cancellationDays: this.state.hasCancellationAllowed
            ? this.state.cancellationDays
            : null,
          visibleDays: this.state.hasVisibleFromAllowed
            ? this.state.visibleDays
            : null,
          bookableDays: this.state.hasBookableFromAllowed
            ? this.state.bookableDays
            : null,
          minAge: this.state.hasMinAge ? this.state.minAge : null,
          maxAge: this.state.hasMaxAge ? this.state.maxAge : null,
          allowBookForFamily: this.state.allowBookForFamily,
          manageRoles: manageRoleIds,
          formId: this.state.selectedForm || null,
        },
        1,
        5
      )
        .then(this.handleSuccessCreatingCours)
        .catch(this.handleErrorCreatingCourse);
    } else if (this.state.courseType === 3) {
      fetchRetry(
        "createCourse",
        {
          clubId: this.state.clubId,
          branchId: this.state.branchId,
          followUpCourseId: this.state.followUpCourseId,
          locationId: this.state.selectedLocation
            ? this.state.selectedLocation
            : null,
          courseType: courseTypeName[2],
          weekDay: parseInt(this.state.weekDay),
          title: this.state.courseName,
          description: this.state.courseDescription,
          allowedRoles: roleIds,
          tags: tagIds,
          places: this.state.coursePlaces,
          priceArr,
          bookingMailMessage: this.state.courseBookingNote,
          date: reformatDate(this.state.infinityStartDate),
          time: this.state.courseTime,
          endDate: this.state.hasExpireDate
            ? reformatDate(this.state.infinityEndDate)
            : null,
          duration: this.state.duration,
          noticePeriodDateUnit:
            dateUnits[parseInt(this.state.dateUnit) - 1].dbName,
          noticePeriod: this.state.noticePeriod,
          negativeDates: this.state.negativeDates,
          visibleDays: this.state.hasVisibleFromAllowed
            ? this.state.visibleDays
            : null,
          bookableDays: this.state.hasBookableFromAllowed
            ? this.state.bookableDays
            : null,
          minAge: this.state.hasMinAge ? this.state.minAge : null,
          maxAge: this.state.hasMaxAge ? this.state.maxAge : null,
          allowBookForFamily: this.state.allowBookForFamily,
          manageRoles: manageRoleIds,
          formId: this.state.selectedForm || null,
        },
        1,
        5
      )
        .then(this.handleSuccessCreatingCours)
        .catch(this.handleErrorCreatingCourse);
    }
  };

  handleErrorCreatingCourse = (err: any) => {
    this.setState({ creatingLoading: false });
    this.showErrorMsg();
    console.error(err);
  };

  handleSuccessCreatingCours = ({ data }: any) => {
    this.setState({ creatingLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Der Kurs wurde erfolgreich erstellt.",
        "success",
        4
      );
      this.setState({
        courseName: "",
        courseDescription: "",
        duration: 60,
        courseDate: getCurrentDayStr(),
        courseTime: "12:00",
        allowOnlinePayment: true,
        allowPaymentOnSite: false,
        coursePlaces: 10,
        redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course#${data.courseId[0]}`,
        errMsg: null,
      });
    } else {
      this.setState({
        errMsg: data.errorMsgDe,
      });
    }
  };

  selectCourseType = (num: number) => {
    this.setState({ courseType: num });
  };

  onSelectedDatesChange = (selectedDates: Array<string>) => {
    let dates: any = this.state.dates.filter((date: any) =>
      selectedDates.includes(date.dateStr)
    );
    const dateStrArr = dates.map((date: any) => date.dateStr);
    selectedDates.forEach((date: string) => {
      if (!dateStrArr.includes(date)) {
        const startDate = strToDateWithMinutes(
          date,
          strTimeToMin(this.state.courseTime)
        );
        const startTime = dateToTimeStr(startDate);
        const endDate = addMinutesToDate(startDate, this.state.duration);
        const endTime = dateToTimeStr(endDate);
        dates.push({
          dateStr: date,
          endDateStr: dateToStr(endDate),
          date: startDate,
          time: startTime,
          endTime,
          name: this.state.courseName,
          description: this.state.courseDescription,
          location: this.state.selectedLocation,
        });
      }
    });
    dates.sort((a: any, b: any) =>
      a.date.getTime() > b.date.getTime() ? 1 : -1
    );
    this.setState({ dates, selectedDates });
  };

  onLocationChange = (index: number, location: string) => {
    let dates = this.state.dates;
    dates[index].location = location;
    this.setState({ dates });
  };

  onTimeChange = (index: number, time: string) => {
    let dates = this.state.dates;
    dates[index].time = time;
    this.setState({ dates });
  };

  onEndTimeChange = (index: number, time: string) => {
    let dates = this.state.dates;
    dates[index].endTime = time;
    this.setState({ dates });
  };

  onEndDateChange = (index: number, endDate: string) => {
    let dates = this.state.dates;
    dates[index].endDateStr = reformatDate(endDate);
    this.setState({ dates });
  };

  handleInfinityStartDateChange = (date: string) => {
    this.setState({ infinityStartDate: date });
  };

  handleInfinityEndDateChange = (date: string) => {
    this.setState({ infinityEndDate: date });
  };

  toggleHasExpireDate = () => {
    this.setState({ hasExpireDate: !this.state.hasExpireDate });
  };

  toggleShowDates = () => {
    this.setState({ showDates: !this.state.showDates });
  };

  handleFormChange = (val: any) => {
    this.setState({ selectedForm: val });
  };

  handleError = (err: any) => {
    this.showErrorMsg();
    console.error(err);
  };

  showErrorMsg = () => {
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
  };

  getBtnText = () => {
    if (this.state.courseType === 1) {
      return `${this.state.dates.length} Kurs${
        this.state.dates.length !== 1 ? "e" : ""
      } erstellen`;
    } else if (this.state.courseType === 2) {
      return "Blockkurs erstellen";
    }
    return "Fortlaufender Kurs erstellen";
  };

  handleAddPriceRole = () => {
    this.setState({ showAddRolePricing: true });
  };

  handleRolePriceSelect = (id: string) => {
    const rolePriceArr: any = this.state.rolePriceArr;
    const index = findWithAttr(rolePriceArr, "id", id);
    rolePriceArr[index].selected = true;
    rolePriceArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));
    this.setState({ rolePriceArr });
  };

  handleRolePriceUnselect = (id: string) => {
    const rolePriceArr: any = this.state.rolePriceArr;
    const index = findWithAttr(rolePriceArr, "id", id);
    rolePriceArr[index].selected = false;
    this.setState({ rolePriceArr });
  };

  onSelectedNegativeDatesChange = (selectedDates: Array<string>) => {
    this.setState({ negativeDates: selectedDates });
  };

  addSector = () => {
    if (this.state.sectorStartDate && this.state.sectorEndDate) {
      if (this.state.sectorStartDate <= this.state.sectorEndDate) {
        let dates: Array<any> = this.state.dates;
        const sectorStartDate = inputStrToDate(this.state.sectorStartDate);
        const sectorEndDate = inputStrToDate(this.state.sectorEndDate);
        const deltaDays =
          (sectorEndDate.getTime() - sectorStartDate.getTime()) / 86400000 + 1;
        const selectedDates: Array<string> = this.state.selectedDates;
        for (var i = 0; i < deltaDays; i++) {
          let startDate = addDaysToDate(copyDate(sectorStartDate), i);
          startDate = strToDateWithMinutes(
            dateToStr(startDate),
            strTimeToMin(this.state.courseTime)
          );
          if (
            startDate.getDay() === parseInt(this.state.weekDaySection) ||
            parseInt(this.state.weekDaySection) === 7
          ) {
            if (i === 0) {
              this.calendarRef.current.handleSetMonth(
                startDate.getFullYear(),
                startDate.getMonth() + 1
              );
            }
            const endDate = addMinutesToDate(startDate, this.state.duration);
            const endTime = dateToTimeStr(endDate);
            const dateStr = dateToStr(startDate);
            if (!selectedDates.includes(dateStr)) {
              selectedDates.push(dateToStr(startDate));
              dates.push({
                dateStr: dateToStr(startDate),
                endDateStr: dateToStr(endDate),
                date: startDate,
                time: this.state.courseTime,
                endTime,
                name: this.state.courseName,
                description: this.state.courseDescription,
                location: this.state.selectedLocation,
              });
            }
          }
        }
        dates.sort((a: any, b: any) =>
          a.date.getTime() > b.date.getTime() ? 1 : -1
        );
        this.setState({ dates, selectedDates, editSectorErrMsg: null });
      } else {
        this.setState({
          editSectorErrMsg: "Das Startdatum darf nicht nach dem Enddatum sein.",
        });
      }
    } else {
      this.setState({
        editSectorErrMsg: "Bitte wähle ein gültiges Start-und Enddatum aus.",
      });
    }
  };

  removeSector = () => {
    if (this.state.sectorStartDate && this.state.sectorEndDate) {
      if (this.state.sectorStartDate <= this.state.sectorEndDate) {
        let dates: Array<any> = this.state.dates;
        const sectorStartDate = inputStrToDate(this.state.sectorStartDate);
        const sectorEndDate = inputStrToDate(this.state.sectorEndDate);
        const deltaDays =
          (sectorEndDate.getTime() - sectorStartDate.getTime()) / 86400000 + 1;
        let selectedDates: Array<string> = this.state.selectedDates;
        for (var i = 0; i < deltaDays; i++) {
          let startDate = addDaysToDate(copyDate(sectorStartDate), i);
          startDate = addMinutesToDate(
            startDate,
            strTimeToMin(this.state.courseTime)
          );
          if (
            startDate.getDay() === parseInt(this.state.weekDaySection) ||
            parseInt(this.state.weekDaySection) === 7
          ) {
            const dateStr = dateToStr(startDate);
            selectedDates = selectedDates.filter(
              (currDate: string) => currDate !== dateStr
            );
            dates = dates.filter(
              (currDate: any) => currDate.dateStr !== dateStr
            );
          }
        }
        this.setState({ dates, selectedDates, editSectorErrMsg: null });
      } else {
        this.setState({
          editSectorErrMsg: "Das Startdatum darf nicht nach dem Enddatum sein.",
        });
      }
    } else {
      this.setState({
        editSectorErrMsg: "Bitte wähle ein gültiges Start-und Enddatum aus.",
      });
    }
  };

  handleWeekDaySectionChange = (val: string) => {
    this.setState({ weekDaySection: val });
  };

  toggleMinAge = () => {
    this.setState({ hasMinAge: !this.state.hasMinAge });
  };

  toggleMaxAge = () => {
    this.setState({ hasMaxAge: !this.state.hasMaxAge });
  };

  handleMaxAgeChange = (val: number) => {
    this.setState({ maxAge: val });
  };

  handleMinAgeChange = (val: number) => {
    this.setState({ minAge: val });
  };

  handleBranchRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.branchRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ branchRoleArr: newRoleArr });
  };

  handleBranchRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.branchRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ branchRoleArr: newRoleArr });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (
      !this.state.branchLoaded ||
      !this.state.courseLoaded ||
      !this.state.roleArrLoaded
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    const selectedRolesWithDefault =
      this.state.roleArr.filter(
        (currRole: any) => currRole.id === "guest" && currRole.selected
      ).length !== 0
        ? this.state.roleArr
            .filter(
              (currRole: any) => currRole.id !== "guest" && currRole.selected
            )
            .map((currRole: any) => currRole.name)
        : [];

    const selectedRolesWithMemberOfBranch =
      this.state.roleArr.filter(
        (currRole: any) => currRole.id === "public" && currRole.selected
      ).length !== 0
        ? this.state.roleArr
            .filter(
              (currRole: any) =>
                !["public", "guest"].includes(currRole.id) && currRole.selected
            )
            .map((currRole: any) => currRole.name)
        : [];

    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Kursverwaltung"
              to={`/club/${this.state.clubId}/branch/${
                this.state.branchId
              }/manage-course${
                this.state.followUpCourseId
                  ? `#${this.state.followUpCourseId}`
                  : ""
              }`}
            />
            <Title
              title={
                this.state.followUpCourseId
                  ? "Folgekurs erstellen"
                  : "Kurs erstellen"
              }
            />
            {this.state.followUpCourseId ? (
              <>
                <SubTitle title="Kurstyp" />
                <div className="margin-bottom">
                  {courseTypes[this.state.course.courseType]?.name}
                </div>
              </>
            ) : (
              <CourseTypeSelector
                courseType={this.state.courseType}
                onChange={this.selectCourseType}
              />
            )}
            <SubTitleTwo title="Allgemeine Einstellungen" />
            <SubTitle
              title="Name"
              helpText="Der Kursname wird jedem der diesen Kurs sieht angezeigt."
            />
            <Input
              value={this.state.courseName}
              onChange={this.handleNameChange}
              placeholder="Kursname"
            />
            <SubTitle
              title="Beschreibung"
              helpText="Die Beschreibung wird jedem der diesen Kurs sieht angezeigt."
            />
            <Input
              value={this.state.courseDescription}
              onChange={this.handleDescriptionChange}
              placeholder="Beschreibung"
            />
            {/* {this.state.courseType === 1 && (
              <>
                <SubTitle title="Datum/Uhrzeit" />
                <div className="course-time-input-container">
                  <input
                    type="date"
                    className="input-container"
                    value={this.state.courseDate}
                    onChange={this.handleDateChange}
                  />
                  <input
                    type="time"
                    className="input-container"
                    value={this.state.courseTime}
                    onChange={this.handleTimeChange}
                  />
                </div>
                <SubTitle title="Dauer (in min)" />
                <Input
                  value={this.state.duration}
                  onChange={this.handleDurationChange}
                  type="number"
                  min={0}
                  step={10}
                />
              </>
            )} */}
            <SubTitle
              title="Örtlichkeit"
              helpText="Örtlichkeiten können in den Bereich Einstellungen verwaltet werden."
            />
            <DropDown
              options={this.state.places}
              onChange={this.handleLocationChange}
              value={this.state.selectedLocation}
              name="locations-dropdown"
              default={"-"}
            />
            <SubTitle
              title="Plätze"
              helpText="Die Anzahl an Leuten die diesen Kurs buchen können."
            />
            <Input
              value={this.state.coursePlaces}
              onChange={this.handleCoursePlacesChange}
              type="number"
              placeholder="Anzahl an Plätzen"
            />
            <SubTitle
              title="Erlaubte Rollen"
              helpText={`Die ausgewählten Rollen können diesen Kurs buchen. Um das Buchen von unregistrierten Benutzern zu erlauben, wähle "Jeder".`}
            />
            <MultiSelector
              searchTextPlaceholder="Rolle"
              arr={this.state.roleArr}
              onSelect={this.handleRoleSelect}
              onUnselect={this.handleRoleUnselect}
            />

            <InfoContainer
              active={selectedRolesWithDefault.length > 0}
            >{`Die Einstellung "Jeder" beinhaltet die ${
              selectedRolesWithDefault.length === 1 ? "Rolle" : "Rollen"
            } "${selectedRolesWithDefault
              .slice(
                0,
                selectedRolesWithDefault.length === 1
                  ? 1
                  : selectedRolesWithDefault.length - 1
              )
              .join('", "')}${
              selectedRolesWithDefault.length > 1
                ? `" und "${
                    selectedRolesWithDefault[
                      selectedRolesWithDefault.length - 1
                    ]
                  }`
                : ""
            }". Daher ${
              selectedRolesWithDefault.length === 1 ? "muss" : "müssen"
            } sie nicht extra ausgewählt werden.`}</InfoContainer>
            <InfoContainer
              active={selectedRolesWithMemberOfBranch.length > 0}
            >{`Die Einstellung "Registrierte Benutzer" beinhaltet die ${
              selectedRolesWithMemberOfBranch.length === 1 ? "Rolle" : "Rollen"
            } "${selectedRolesWithMemberOfBranch
              .slice(
                0,
                selectedRolesWithMemberOfBranch.length === 1
                  ? 1
                  : selectedRolesWithMemberOfBranch.length - 1
              )
              .join('", "')}${
              selectedRolesWithMemberOfBranch.length > 1
                ? `" und "${
                    selectedRolesWithMemberOfBranch[
                      selectedRolesWithMemberOfBranch.length - 1
                    ]
                  }`
                : ""
            }". Daher ${
              selectedRolesWithMemberOfBranch.length === 1 ? "muss" : "müssen"
            } sie nicht extra ausgewählt werden.`}</InfoContainer>
            <SubTitle
              title={
                this.state.courseType === 1 || this.state.courseType === 2
                  ? "Preis"
                  : "Preis pro Monat"
              }
              helpText={
                this.state.courseType === 1 || this.state.courseType === 2
                  ? "Einmaliger Preis des Kurses."
                  : "Monatlicher Preis des Kurses."
              }
            />
            <table className="table-price">
              <thead>
                <tr>
                  <th>Löschen</th>
                  <th>Rolle</th>
                  <th>Preis (in €)</th>
                  <th>Immer anzeigen</th>
                </tr>
              </thead>
              <tbody>
                {this.state.rolePriceArr
                  .filter((role: any) => role.selected)
                  .map((role: any) => (
                    <tr>
                      {role.id !== "default" ? (
                        <td
                          onClick={() => {
                            this.handleRolePriceUnselect(role.id);
                          }}
                          className="pointer center"
                        >
                          <FontAwesomeIcon icon={faTimes} size="lg" />
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td>
                        <div className="flex">
                          {role.name}{" "}
                          {role.id === "default" && (
                            <div className="info-icon-container margin-left">
                              <FontAwesomeIcon
                                icon={faQuestionCircle}
                                size="1x"
                                onClick={() => {}}
                                className="back-element-icon"
                              />
                              <div className="info-text-container">
                                <div className="info-text-triangle"></div>
                                <div className="info-text-inner-container">
                                  Der Preis, wenn das Mitglied keine der
                                  ausgewählten Rollen hat. Auch der Preis für
                                  Gäste und nicht Mitglieder.
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <Input
                          value={role.val}
                          onChange={(val: number) => {
                            this.handlePriceRoleChange(role.id, val);
                          }}
                          type="number"
                          placeholder="Preis"
                          className="no-margin"
                        />
                      </td>
                      <td>
                        <TextCheckbox
                          val={role.showAlways}
                          onChange={() => {
                            this.handleShowAlwaysChange(role.id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                <tr className="bold">
                  <td
                    className="center pointer"
                    onClick={this.handleAddPriceRole}
                  >
                    +
                    <div
                      className={`multiselector-add-container${
                        this.state.showAddRolePricing
                          ? " multiselector-show-add-container"
                          : ""
                      }`}
                      ref={this.wrapperRef}
                    >
                      <div className="multiselector-add-text-container">
                        <input
                          placeholder="Rolle"
                          value={this.state.searchTextPriceRole}
                          onChange={this.handleChangeSearchTextPriceRole}
                        />
                        <div className="icon-container">
                          <FontAwesomeIcon icon={faSearch} size="1x" />
                        </div>
                      </div>
                      <div className="multiselector-scroll-container">
                        {this.state.rolePriceArr
                          .filter(
                            (item: any) =>
                              !item.selected &&
                              item.name
                                .toLowerCase()
                                .includes(
                                  this.state.searchTextPriceRole.toLowerCase()
                                )
                          )
                          .map((item: any) => (
                            <div
                              className="multiselector-select-element-container"
                              onClick={() => {
                                this.handleRolePriceSelect(item.id);
                              }}
                              key={item.id}
                            >
                              <div
                                className="point"
                                style={{ backgroundColor: item.color }}
                              ></div>
                              {item.name}
                            </div>
                          ))}
                      </div>
                    </div>
                  </td>
                  <td colSpan={3}></td>
                </tr>
              </tbody>
            </table>
            <SubTitle
              title="Buchungsnotiz"
              helpText="Text der bei einer erfolgreichen Buchung angezeigt und per E-Mail mitgesendet wird."
            />
            <TextArea
              value={this.state.courseBookingNote}
              onChange={this.handleBookingNoteChange}
              placeholder="Buchungsnotiz"
            />
            {(this.state.courseType === 1 || this.state.courseType === 2) && (
              <>
                <SubTitle
                  title="Stornierungsfrist (in Tagen)"
                  helpText="Vor dieser Anzahl an Tagen vor Kursbeginn kann der Kurs storniert werden."
                />

                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasCancellationAllowed}
                      onChange={this.toggleCancellationAllowed}
                    />
                  </div>
                  {this.state.hasCancellationAllowed ? (
                    <Input
                      value={this.state.cancellationDays}
                      onChange={this.handleCancellationDaysChange}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Keine Stornierungen erlaubt
                    </div>
                  )}
                </div>
                {/* <SubTitle
                  title="Sichtbar ab x Tagen vor Kursbeginn"
                  helpText="Vor dieser Anzahl an Tagen vor Kursbeginn wird der Kurs angezeigt."
                />

                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasVisibleFromAllowed}
                      onChange={this.toggleVisibleFromAllowed}
                    />
                  </div>
                  {this.state.hasVisibleFromAllowed ? (
                    <Input
                      value={this.state.visibleDays}
                      onChange={this.handleVisibleFromDaysChange}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Immer Sichtbar
                    </div>
                  )}
                </div> */}
                <SubTitle
                  title="Buchbar ab x Tagen vor Kursbeginn"
                  helpText="Vor dieser Anzahl an Tagen vor Kursbeginn kann der Kurs gebucht werden."
                />

                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasBookableFromAllowed}
                      onChange={this.toggleBookableFromAllowed}
                    />
                  </div>
                  {this.state.hasBookableFromAllowed ? (
                    <Input
                      value={this.state.bookableDays}
                      onChange={this.handleBookableFromChange}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Immer Buchbar
                    </div>
                  )}
                </div>
                <SubTitle
                  title="Mindestalter"
                  helpText="Ab diesem Alter kann der Kurs gebucht werden. (Dieses Alter kann den Kurs buchen)"
                />

                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasMinAge}
                      onChange={this.toggleMinAge}
                    />
                  </div>
                  {this.state.hasMinAge ? (
                    <Input
                      value={this.state.minAge}
                      onChange={this.handleMinAgeChange}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Kein Mindestalter
                    </div>
                  )}
                </div>
                <SubTitle
                  title="Maximalalter"
                  helpText="Nach diesem Alter kann der Kurs nicht mehr gebucht werden. (Dieses Alter kann den Kurs buchen)"
                />

                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasMaxAge}
                      onChange={this.toggleMaxAge}
                    />
                  </div>
                  {this.state.hasMaxAge ? (
                    <Input
                      value={this.state.maxAge}
                      onChange={this.handleMaxAgeChange}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Kein Maximalalter
                    </div>
                  )}
                </div>
                <SubTitle
                  title="Erlaube Buchungen für Familienmitglieder"
                  helpText="Stellt ein, ob ein Kunde für seine Familienmitglieder buchen kann."
                />
                <Switch
                  value={this.state.allowBookForFamily}
                  onChange={() => {
                    this.setState({
                      allowBookForFamily: !this.state.allowBookForFamily,
                    });
                  }}
                />
                <div className="small-space"></div>
                <SubTitle
                  title="Tags"
                  helpText="Tags können hinzugefügt werden um die Suche nach Kurse zu vereinfachen. Tags können in den Bereich Einstellungen verwaltet werden."
                />
                <MultiSelector
                  searchTextPlaceholder="Tags"
                  arr={this.state.tagArr}
                  onSelect={this.handleTagSelect}
                  onUnselect={this.handleTagUnselect}
                />
                <SubTitle
                  title="diese Rollen automatisch zuweisen"
                  helpText="Die ausgewählten Rollen werden dem Nutzer beim Bucheb des Kurses automatisch zugewiesen. Beim stornieren des Kurses werden die Rollen nicht wieder weggenommen."
                />
                <MultiSelector
                  searchTextPlaceholder="Rolle"
                  onSelect={this.handleBranchRoleSelect}
                  onUnselect={this.handleBranchRoleUnselect}
                  arr={this.state.branchRoleArr}
                  className="margin-bottom"
                />

                {this.state.branch?.settings?.useForm && (
                  <>
                    <SubTitle
                      title="Formular"
                      helpText="Verknüpfe ein Formular mit diesem Kurs, jeder Nutzer muss beim Buchen des Kurses das Formular ausfüllen. Formulare können in den Bereichseinstellungen verwaltet werden."
                    />
                    <DropDown
                      options={this.state.forms}
                      onChange={this.handleFormChange}
                      value={this.state.selectedForm}
                      name="form-dropdown"
                      default={"-"}
                    />
                  </>
                )}
                <SubTitleTwo title="Termine" />
                <SubTitle
                  title="Termin Bereich auswählen"
                  helpText="Erstelle oder lösche hiermit ganze Bereiche von Terminen."
                />
                <DateSectionSelector
                  sectorStartDate={this.state.sectorStartDate}
                  sectorEndDate={this.state.sectorEndDate}
                  handleSectorStartDateChange={this.handleSectorStartDateChange}
                  handleSectorEndDateChange={this.handleSectorEndDateChange}
                  addSector={this.addSector}
                  removeSector={this.removeSector}
                  editSectorErrMsg={this.state.editSectorErrMsg}
                  weekDaySection={this.state.weekDaySection}
                  handleWeekDaySectionChange={this.handleWeekDaySectionChange}
                />
                <SubTitle
                  title="Uhrzeit"
                  helpText={
                    this.state.courseType === 1 || this.state.courseType === 2
                      ? "Standard Uhrzeit. Kann unten pro Kurs noch verändert werden."
                      : "Startzeit des Kurses."
                  }
                />
                <div className="course-time-input-container">
                  <input
                    type="time"
                    className="input-container"
                    value={this.state.courseTime}
                    onChange={this.handleTimeChange}
                  />
                </div>
                <SubTitle
                  title="Dauer (in min)"
                  helpText={
                    this.state.courseType === 1 || this.state.courseType === 2
                      ? "Standard Dauer. Kann unten pro Kurs noch verändert werden."
                      : "Dauer des Kurses."
                  }
                />
                <Input
                  value={this.state.duration}
                  onChange={this.handleDurationChange}
                  type="number"
                  min={0}
                  step={10}
                />
                <Calendar
                  setSelectedDates={this.onSelectedDatesChange}
                  onSelectedNegativeDatesChange={() => {}}
                  selectedDates={this.state.selectedDates}
                  ref={this.calendarRef}
                />
              </>
            )}
            {this.state.courseType === 3 && (
              <>
                <SubTitle
                  title="Kündigungsfrist"
                  helpText="Frist bis zum Wirksamwerden der Kündigung."
                />
                <div className="flex two-input-container">
                  <DropDown
                    options={dateUnits}
                    onChange={this.handleDateUnitsChange}
                    value={this.state.dateUnit}
                    name="locations-dropdown"
                  />
                  <Input
                    value={this.state.noticePeriod}
                    onChange={this.handleNoticePeriodChange}
                    type="number"
                    min={0}
                    step={10}
                  />
                </div>
                {/* <SubTitle
                  title="Sichtbar ab x Tagen vor Kursbeginn"
                  helpText="Vor dieser Anzahl an Tagen vor Kursbeginn wird der Kurs angezeigt."
                />

                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasVisibleFromAllowed}
                      onChange={this.toggleVisibleFromAllowed}
                    />
                  </div>
                  {this.state.hasVisibleFromAllowed ? (
                    <Input
                      value={this.state.visibleDays}
                      onChange={this.handleVisibleFromDaysChange}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Immer Sichtbar
                    </div>
                  )}
                </div> */}
                <SubTitle
                  title="Buchbar ab x Tagen vor Kursbeginn"
                  helpText="Vor dieser Anzahl an Tagen vor Kursbeginn kann der Kurs gebucht werden."
                />

                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasBookableFromAllowed}
                      onChange={this.toggleBookableFromAllowed}
                    />
                  </div>
                  {this.state.hasBookableFromAllowed ? (
                    <Input
                      value={this.state.bookableDays}
                      onChange={this.handleBookableFromChange}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Immer Buchbar
                    </div>
                  )}
                </div>
                <SubTitle
                  title="Mindestalter"
                  helpText="Ab diesem Alter kann der Kurs gebucht werden. (Dieses Alter kann den Kurs buchen)"
                />

                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasMinAge}
                      onChange={this.toggleMinAge}
                    />
                  </div>
                  {this.state.hasMinAge ? (
                    <Input
                      value={this.state.minAge}
                      onChange={this.handleMinAgeChange}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Kein Mindestalter
                    </div>
                  )}
                </div>
                <SubTitle
                  title="Maximalalter"
                  helpText="Nach diesem Alter kann der Kurs nicht mehr gebucht werden. (Dieses Alter kann den Kurs buchen)"
                />
                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasMaxAge}
                      onChange={this.toggleMaxAge}
                    />
                  </div>
                  {this.state.hasMaxAge ? (
                    <Input
                      value={this.state.maxAge}
                      onChange={this.handleMaxAgeChange}
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Kein Maximalalter
                    </div>
                  )}
                </div>
                <SubTitle
                  title="Erlaube Buchungen für Familienmitglieder"
                  helpText="Stellt ein, ob ein Kunde für seine Familienmitglieder buchen kann."
                />
                <Switch
                  value={this.state.allowBookForFamily}
                  onChange={() => {
                    this.setState({
                      allowBookForFamily: !this.state.allowBookForFamily,
                    });
                  }}
                />
                <div className="small-space"></div>
                <SubTitle
                  title="Tags"
                  helpText="Tags können hinzugefügt werden um die Suche nach Kurse zu vereinfachen. Tags können in den Bereich Einstellungen verwaltet werden."
                />
                <MultiSelector
                  searchTextPlaceholder="Tags"
                  arr={this.state.tagArr}
                  onSelect={this.handleTagSelect}
                  onUnselect={this.handleTagUnselect}
                />
                <SubTitle
                  title="diese Rollen automatisch zuweisen"
                  helpText="Die ausgewählten Rollen werden dem Nutzer beim Bucheb des Kurses automatisch zugewiesen. Beim stornieren des Kurses werden die Rollen nicht wieder weggenommen."
                />
                <MultiSelector
                  searchTextPlaceholder="Rolle"
                  onSelect={this.handleBranchRoleSelect}
                  onUnselect={this.handleBranchRoleUnselect}
                  arr={this.state.branchRoleArr}
                  className="margin-bottom"
                />
                {this.state.branch?.settings?.useForm && (
                  <>
                    <SubTitle
                      title="Formular"
                      helpText="Verknüpfe ein Formular mit diesem Kurs, jeder Nutzer muss beim Buchen des Kurses das Formular ausfüllen. Formulare können in den Bereichseinstellungen verwaltet werden."
                    />
                    <DropDown
                      options={this.state.forms}
                      onChange={this.handleFormChange}
                      value={this.state.selectedForm}
                      name="form-dropdown"
                      default={"-"}
                    />
                  </>
                )}
                <SubTitleTwo title="Termine" />
                <SubTitle
                  title="Anfangsdatum"
                  helpText="Ab diesem Zeitpunkt finden Termine dieses Kurses statt (Dieser Tag eingeschlossen). Ein angebrochener Monat wird als vollständig abgerechnet."
                />
                <div className="course-time-input-container">
                  <input
                    type="date"
                    className="input-container"
                    value={this.state.infinityStartDate}
                    onChange={(val: any) => {
                      this.handleInfinityStartDateChange(val.target.value);
                    }}
                  />
                </div>
                <SubTitle
                  title="Auslaufdatum"
                  helpText="An diesem Tag wird der Kurs auslaufen (Dieser Tag findet noch statt)."
                />

                <div className="course-time-input-container">
                  <div className="margin-right">
                    <Switch
                      value={this.state.hasExpireDate}
                      onChange={this.toggleHasExpireDate}
                    />
                  </div>
                  {this.state.hasExpireDate ? (
                    <input
                      type="date"
                      className="input-container expireDate-container"
                      value={this.state.infinityEndDate}
                      onChange={(val: any) => {
                        this.handleInfinityEndDateChange(val.target.value);
                      }}
                    />
                  ) : (
                    <div className="input-container expireDate-container">
                      Kein Auslaufdatum
                    </div>
                  )}
                </div>
                <SubTitle
                  title="Wöchentliche Wiederholung"
                  helpText="Der Kurs findet jede Woche an diesem Wochentag statt."
                />
                <DropDown
                  options={weekDays}
                  onChange={this.handleWeekDayChange}
                  value={this.state.weekDay}
                  name="locations-dropdown"
                />
                <SubTitle title="Uhrzeit" helpText={"Startzeit des Kurses."} />
                <div className="course-time-input-container">
                  <input
                    type="time"
                    className="input-container"
                    value={this.state.courseTime}
                    onChange={this.handleTimeChange}
                  />
                </div>
                <SubTitle
                  title="Dauer (in min)"
                  helpText={"Dauer des Kurses."}
                />
                <Input
                  value={this.state.duration}
                  onChange={this.handleDurationChange}
                  type="number"
                  min={0}
                  step={10}
                />
                <Calendar
                  setSelectedDates={() => {}}
                  onSelectedNegativeDatesChange={
                    this.onSelectedNegativeDatesChange
                  }
                  weekDay={parseInt(this.state.weekDay)}
                  negativeDates={this.state.negativeDates}
                  startDate={inputStrToDate(this.state.infinityStartDate)}
                  endDate={
                    this.state.hasExpireDate && this.state.infinityEndDate
                      ? inputStrToDate(this.state.infinityEndDate)
                      : null
                  }
                  selectedDates={this.state.selectedDates}
                  ref={this.calendarRef}
                />
              </>
            )}
            {(this.state.courseType === 1 || this.state.courseType === 2) && (
              <>
                <div
                  className="more-info-container"
                  onClick={this.toggleShowDates}
                >
                  <div className="more-info-inner-container">
                    {this.state.showDates ? (
                      <FontAwesomeIcon icon={faChevronDown} size="sm" />
                    ) : (
                      <FontAwesomeIcon icon={faChevronUp} size="sm" />
                    )}
                  </div>
                  {this.state.dates.length} Termine ausgewählt
                </div>
                {this.state.showDates && (
                  <>
                    {this.state.dates.map((date: any, index: number) => (
                      <DateComponent
                        date={reformatDateReverse(date.dateStr)}
                        endDate={reformatDateReverse(date.endDateStr)}
                        courseTime={date.time}
                        endTime={date.endTime}
                        handleTimeChange={(time: string) => {
                          this.onTimeChange(index, time);
                        }}
                        handleEndTimeChange={(time: string) => {
                          this.onEndTimeChange(index, time);
                        }}
                        handleLocationChange={(location: string) => {
                          this.onLocationChange(index, location);
                        }}
                        handleEndDateChange={(date: string) => {
                          this.onEndDateChange(index, date);
                        }}
                        places={this.state.places}
                        selectedLocation={date.location}
                      />
                    ))}
                  </>
                )}
              </>
            )}
            <ErrorMessage message={this.state.errMsg} />
            <div className="modal-btn-container">
              <SecButton
                title={this.getBtnText()}
                onClick={this.handleCreateCourse}
                color="green"
                change={true}
                loading={this.state.creatingLoading}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
