import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./CourseBooking.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import { generateQRCode } from "../../functions/qr";
import BackElement from "../../components/BackElement";
import Title from "../../components/Title";
import TestMode from "../../components/TestMode";
import PaymentStatus from "../../components/PaymentStatus";
import {
  dateDurationToFullStr,
  dateToFullStr,
  dateToSmallStr,
  firebaseDateToDateObj,
  formatCode,
  formatDateUnit,
  priceToStr,
  strToDate,
} from "../../functions/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { weekDays } from "../../services/constants";
import SecButton from "../../components/SecButton";
import ErrorMessage from "../../components/ErrorMessage";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { firestore } from "../../services/firebase";
import {
  faChevronDown,
  faChevronUp,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { getPaymentTable } from "../../functions/getPaymentTable";
import { getBookingStatus } from "../../functions/getBookingStatus";
import PaymentMethod from "../../components/PaymentMethod";
import { formatLineBreaks } from "../../functions/formatHTML";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId: string;
    courseBookingId: string;
    clientKey: string | undefined;
  }>;

export default class CourseBooking extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseBookingId: string;
    clientKey: string | undefined;
    courseBookingLoaded: boolean;
    courseBookingData: any;
    qrCode: string;
    course: any;
    courseLoaded: boolean;
    cancellationLoading: boolean;
    errorMsgCancellation: null | string;
    showConfirmCancelCourse: boolean;
    showConfirmCancelSubscription: boolean;
    listenerActive: boolean;
    paymentArr: Array<any>;
    showMore: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseId: this.props.match.params.courseId,
      courseBookingId: this.props.match.params.courseBookingId,
      clientKey: this.props.match.params.clientKey,
      courseBookingLoaded: false,
      courseBookingData: {},
      qrCode: "",
      course: {},
      courseLoaded: false,
      cancellationLoading: false,
      errorMsgCancellation: null,
      showConfirmCancelCourse: false,
      showConfirmCancelSubscription: false,
      listenerActive: false,
      paymentArr: [],
      showMore: false,
    };
  }

  componentDidMount = () => {
    this.requestCourse();
    if (!this.state.listenerActive) {
      if (this.context.user) {
        firestore
          .collection("courses")
          .doc(this.state.courseId)
          .collection("courseBookings")
          .doc(this.state.courseBookingId)
          .onSnapshot(() => {
            this.requestCoursesBooking();
          });
      } else {
        this.requestCoursesBooking();
      }
      this.setState({ listenerActive: true });
    }
  };

  requestCourse = () => {
    fetchRetry(
      "getCourseFromIdFNew",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleCourseSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCourseSuccess = ({ data }: any) => {
    this.setState({ courseLoaded: true });
    if (data.success) {
      this.setState({ course: data.data[0] });
    }
  };

  requestCoursesBooking = () => {
    this.setState({ courseBookingLoaded: false });
    fetchRetry(
      "getCourseBookingF",
      {
        courseBookingId: this.state.courseBookingId,
        clientKey: this.state.clientKey,
      },
      1,
      5
    )
      .then(this.handleCoursesBookingSuccess)
      .catch(this.handleError);
  };

  handleCoursesBookingSuccess = async ({ data }: any) => {
    this.setState({ courseBookingLoaded: true });
    if (data.success) {
      const courseBookingData = data.data;
      const paymentArr: Array<any> = getPaymentTable(courseBookingData);
      const targetUserId: string = courseBookingData.userId;
      this.context.setSelectedUserId(targetUserId);
      this.setState({
        courseBookingData: courseBookingData,
        paymentArr,
        qrCode: await generateQRCode(courseBookingData.courseBookingSimpleId),
      });
    }
  };

  handleCancellateCourse = () => {
    this.setState({ showConfirmCancelCourse: true });
  };

  handleCancellateSubscription = () => {
    this.setState({ showConfirmCancelSubscription: true });
  };

  hideConfirmationCancelCourse = () => {
    this.setState({ showConfirmCancelCourse: false });
  };

  hideConfirmationCancelSubscription = () => {
    this.setState({ showConfirmCancelSubscription: false });
  };

  handleCancellateCourseConfirmed = () => {
    this.setState({
      cancellationLoading: true,
      showConfirmCancelSubscription: false,
      showConfirmCancelCourse: false,
    });
    fetchRetry(
      "cancelCourseF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        courseBookingId: this.state.courseBookingId,
        clientKey: this.state.clientKey,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleCoursesCanceledSuccess)
      .catch(this.handleError);
  };

  handleCoursesCanceledSuccess = ({ data }: any) => {
    this.setState({ cancellationLoading: false });
    if (data.success) {
      this.setState({ errorMsgCancellation: null });
      this.requestCoursesBooking();
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    if (!this.state.courseBookingLoaded || !this.state.courseLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    const paymentStatus =
      this.state.courseBookingData.paymentStatus === "free"
        ? "canceled"
        : this.state.courseBookingData.paymentStatus;

    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmCancelCourse}
          title="Angebot stornieren"
          msg="Bist du sicher, dass du dieses Angebot stornieren willst?"
          handleClose={this.hideConfirmationCancelCourse}
          handleConfirm={this.handleCancellateCourseConfirmed}
        />
        <ConfirmationModal
          show={this.state.showConfirmCancelSubscription}
          title="Abonnement kündigen"
          msg="Bist du sicher, dass du das Abonnement kündigen willst?"
          handleClose={this.hideConfirmationCancelSubscription}
          handleConfirm={this.handleCancellateCourseConfirmed}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            {this.context.user ? (
              <BackElement
                text="zurück zu meinen Angeboten"
                to={`/club/${this.state.clubId}/branch/${this.state.branchId}/mycourses#${this.state.courseBookingId}`}
              />
            ) : (
              <BackElement
                text="zurück zur Kursauswahl"
                to={`/club/${this.state.clubId}/branch/${this.state.branchId}/course`}
              />
            )}
            <TestMode active={this.state.courseBookingData.testmode} />
            <Title title="Angebot" />
            <div className="courseBooking-course-info-container">
              <div>
                {!this.state.course.imgLowRes ? (
                  <div className="single-course-img">
                    <FontAwesomeIcon
                      icon={faImage}
                      size="6x"
                      className="image-icon"
                    />
                  </div>
                ) : (
                  <img
                    className="single-course-img"
                    src={this.state.course.imgLowRes}
                    alt="Kursbild"
                  />
                )}
              </div>
              <div>
                {this.state.course.courseType === "single-course" && (
                  <>
                    <h2>{this.state.course.title}</h2>
                    <span>{this.state.course.description}</span>
                    <div className="course-text-container">
                      Wann:{" "}
                      <span className="normal-weight">
                        {dateToFullStr(
                          firebaseDateToDateObj(this.state.course.startTime)
                        )}
                      </span>
                    </div>
                    {this.state.course?.location?.name && (
                      <div className="course-text-container">
                        Wo:{" "}
                        <span className="normal-weight">
                          {this.state.course.location?.name}
                        </span>
                      </div>
                    )}
                    {this.state.course.bookingMailMessage && (
                      <div className="course-text-container">
                        Notiz:{" "}
                        <span className="normal-weight">
                          {formatLineBreaks(
                            this.state.course.bookingMailMessage
                          )}
                        </span>
                      </div>
                    )}
                  </>
                )}
                {this.state.course.courseType === "block-course" && (
                  <>
                    <h2>{this.state.course.title}</h2>
                    <span>{this.state.course.description}</span>
                    <div className="course-text-container">
                      Wann:{" "}
                      <span className="normal-weight">
                        {this.state.course.courses.map(
                          (course: any, index: number) => (
                            <div key={index}>
                              -{" "}
                              {dateDurationToFullStr(
                                firebaseDateToDateObj(course.startTime),
                                firebaseDateToDateObj(course.endTime)
                              )}{" "}
                              {course.location?.name ? (
                                <>({course.location.name})</>
                              ) : (
                                ""
                              )}
                            </div>
                          )
                        )}
                      </span>
                    </div>
                    {this.state.course.bookingMailMessage && (
                      <div className="course-text-container">
                        Notiz:{" "}
                        <span className="normal-weight">
                          {formatLineBreaks(
                            this.state.course.bookingMailMessage
                          )}
                        </span>
                      </div>
                    )}
                  </>
                )}
                {this.state.course.courseType === "infinity-course" && (
                  <>
                    <h2>{this.state.course.title}</h2>
                    <span>{this.state.course.description}</span>
                    <div className="course-text-container">
                      Wann:{" "}
                      <span className="normal-weight">
                        jeden {weekDays[this.state.course.weekDay].name}
                      </span>
                    </div>
                    {this.state.course.negativeDates &&
                      this.state.course.negativeDates.filter(
                        (dateStr: string) => {
                          const date = strToDate(dateStr);
                          return date > new Date();
                        }
                      ).length > 0 && (
                        <div className="course-text-container">
                          Ausfallende Termine:{" "}
                          <span className="normal-weight">
                            {this.state.course.negativeDates
                              .filter((dateStr: string) => {
                                const date = strToDate(dateStr);
                                return date > new Date();
                              })
                              .sort((a: any, b: any) =>
                                strToDate(a).getTime() > strToDate(b).getTime()
                                  ? 1
                                  : -1
                              )
                              .slice(
                                0,
                                this.state.showMore
                                  ? this.state.course?.negativeDates.length
                                  : 3
                              )
                              .map((date: string, index: number) => (
                                <div key={index}>- {date} </div>
                              ))}
                          </span>
                          {this.state.course?.negativeDates.length > 3 && (
                            <div
                              className="more-info-container"
                              onClick={this.toggleShowMore}
                            >
                              <div className="more-info-inner-container">
                                {!this.state.showMore ? (
                                  <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="sm"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faChevronUp}
                                    size="sm"
                                  />
                                )}
                              </div>
                              {this.state.showMore
                                ? "weniger anzeigen"
                                : "mehr anzeigen"}
                            </div>
                          )}
                        </div>
                      )}
                    {this.state.course?.location?.name && (
                      <div className="course-text-container">
                        Wo:{" "}
                        <span className="normal-weight">
                          {this.state.course?.location?.name}
                        </span>
                      </div>
                    )}
                    {this.state.course.bookingMailMessage && (
                      <div className="course-text-container">
                        Notiz:{" "}
                        <span className="normal-weight">
                          {formatLineBreaks(
                            this.state.course.bookingMailMessage
                          )}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <Title title="Buchungsinformationen" />
            <div>
              {this.state.courseBookingData.billing === "subscription" ? (
                <>
                  <table className="course-booking-information-table course-booking-information">
                    <tbody>
                      <tr className="no-border">
                        <td>Status</td>
                        <td>
                          {getBookingStatus(this.state.courseBookingData)}
                        </td>
                      </tr>
                      <tr>
                        <td>Methode</td>
                        <td>
                          <PaymentMethod
                            clubId={this.state.clubId}
                            branchId={this.state.branchId}
                            courseId={this.state.courseId}
                            courseBookingId={this.state.courseBookingId}
                            method={this.state.courseBookingData.method}
                            refresh={this.requestCoursesBooking}
                            selectedMandateId={
                              this.state.courseBookingData?.mandateId
                            }
                            type="course"
                            editable={this.state.courseBookingData.active}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Preis pro Monat</td>
                        <td>
                          {priceToStr(this.state.courseBookingData.montlyPrice)}
                          €
                        </td>
                      </tr>
                      <tr>
                        <td>Buchungszeitpunkt</td>
                        <td>
                          {dateToSmallStr(
                            firebaseDateToDateObj(
                              this.state.courseBookingData.created_at
                            )
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Kündigungsfrist</td>
                        <td>
                          {this.state.courseBookingData.noticePeriod}{" "}
                          {formatDateUnit(
                            this.state.courseBookingData.noticePeriodDateUnit,
                            this.state.courseBookingData.noticePeriod
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {this.state.courseBookingData.price !== 0 && (
                    <table className="course-booking-information-table">
                      <thead>
                        <tr>
                          <th>Monat</th>
                          <th>Zahlungsstatus</th>
                          <th>Preis</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.paymentArr.map((currPayment: any) => (
                          <tr>
                            <td>{currPayment.monthStr}</td>
                            <td>
                              <div className="flex">
                                <PaymentStatus
                                  type={currPayment.paymentStatus}
                                />
                                {currPayment.refunded && (
                                  <div className="margin-left">
                                    <PaymentStatus
                                      type={currPayment.refundStatus}
                                    />
                                  </div>
                                )}
                                {currPayment.hasChargebacks && (
                                  <div className="margin-left">
                                    <PaymentStatus type={"hasChargebacks"} />
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>{currPayment.priceStr}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              ) : (
                <table className="course-booking-information-table">
                  <tbody>
                    <tr className="no-border">
                      <td>Status</td>
                      <td>
                        {this.state.courseBookingData.active
                          ? "Gebucht"
                          : this.state.courseBookingData.refunded
                          ? "Storniert"
                          : "Nicht Gebucht"}
                      </td>
                    </tr>
                    <tr>
                      <td>Methode</td>
                      <td>
                        <PaymentMethod
                          clubId={this.state.clubId}
                          branchId={this.state.branchId}
                          courseId={this.state.courseId}
                          courseBookingId={this.state.courseBookingId}
                          method={this.state.courseBookingData.method}
                          refresh={this.requestCoursesBooking}
                          selectedMandateId={
                            this.state.courseBookingData?.mandateId
                          }
                          type="course"
                          editable={this.state.courseBookingData.active}
                        />
                      </td>
                    </tr>
                    {this.state.courseBookingData.price > 0 && (
                      <tr>
                        <td>Bezahl Status</td>
                        <td>
                          <div className="flex">
                            <PaymentStatus type={paymentStatus} />
                            {paymentStatus === "open" && (
                              <div className="margin-left">
                                <a
                                  href={
                                    this.state.courseBookingData.checkoutUrl
                                  }
                                >
                                  <div>Jetzt bezahlen</div>
                                </a>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Preis</td>
                      <td>{priceToStr(this.state.courseBookingData.price)}€</td>
                    </tr>
                    <tr>
                      <td>Buchungszeitpunkt</td>
                      <td>
                        {dateToSmallStr(
                          firebaseDateToDateObj(
                            this.state.courseBookingData.created_at
                          )
                        )}
                      </td>
                    </tr>
                    {this.state.courseBookingData.refunded ? (
                      <>
                        <tr>
                          <td>Stornierungs Status</td>
                          <td>
                            <div className="flex">
                              <PaymentStatus
                                type={this.state.courseBookingData.refundStatus}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {this.state.courseBookingData.cancellationDays !=
                          null && (
                          <>
                            <tr>
                              <td>Stornierungsfrist</td>
                              <td>
                                {" bis "}
                                {dateToFullStr(
                                  firebaseDateToDateObj(
                                    this.state.courseBookingData
                                      .cancellationDate
                                  )
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              )}
              <div className="qrCode-container">
                <img src={this.state.qrCode} alt="qr-code" />
                <span>
                  <span className="bold">Code </span>
                  {formatCode(
                    this.state.courseBookingData.courseBookingSimpleId
                  )}
                </span>
              </div>
              {this.state.courseBookingData.billing === "subscription" ? (
                <>
                  {!this.state.courseBookingData.subscriptionCancelled && (
                    <div className="cancellationContainer">
                      <ErrorMessage message={this.state.errorMsgCancellation} />
                      <SecButton
                        change={!this.state.courseBookingData.refunded}
                        color="red"
                        loading={this.state.cancellationLoading}
                        onClick={this.handleCancellateSubscription}
                        title="Kündigen"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {this.state.course.cancellationDays !== null && (
                    <div className="cancellationContainer">
                      <ErrorMessage message={this.state.errorMsgCancellation} />
                      <SecButton
                        change={!this.state.courseBookingData.refunded}
                        color="red"
                        loading={this.state.cancellationLoading}
                        onClick={this.handleCancellateCourse}
                        title="Angebot stornieren"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
