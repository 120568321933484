import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { storage } from "../../services/firebase";
import "./EditCourse.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import {
  addDaysToDate,
  addMinutesToDate,
  copyDate,
  dateToStr,
  dateToStrInput,
  dateToTimeStr,
  findWithAttr,
  firebaseDateToDateObj,
  inputStrToDate,
  reformatDate,
  reformatDateReverse,
  removeTimeZoneOffset,
  stringDateAndTimeToDate,
  strTimeToMin,
  strToDate,
  strToDateWithMinutes,
} from "../../functions/utils";
import Input from "../../components/Input";
import SecButton from "../../components/SecButton";
import MultiSelector from "../../components/MultiSelector";
import DropDown from "../../components/DropDown";
import SubTitle from "../../components/SubTitle";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../../contexts/MainContext";
import {
  courseTypes,
  dateUnits,
  dateUnitsArr,
  weekDays,
} from "../../services/constants";
import Switch from "../../components/Switch";
import Calendar from "../../components/Calendar";
import ErrorMessage from "../../components/ErrorMessage";
import Title from "../../components/Title";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import DateComponent from "../../components/DateComponent";
import InfoContainer from "../../components/InfoContainer";
import TextCheckbox from "../../components/TextCheckbox";
import SubTitleTwo from "../../components/SubTitleTwo";
import {
  faBell,
  faChevronDown,
  faChevronUp,
  faImage,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import DateSectionSelector from "../../components/DateSectionSelector";
import ConfirmationDeleteCoursesInRowModal from "../../components/modals/ConfirmationDeleteCoursesInRowModal";
import CourseNotificationModal from "../../components/modals/CourseNotificationModal";
import TextArea from "../../components/TextArea";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId: string;
  }>;

export default class EditCourse extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseId: string;
    branchLoaded: boolean;
    courseLoaded: boolean;
    updateLoading: boolean;
    branch: any;
    course: any;
    courseTitle: string;
    courseDescription: string;
    coursePlaces: number;
    courseDate: string;
    courseOldDate: string;
    courseEndDate: string;
    courseOldEndDate: string;
    courseTime: string;
    courseOldTime: string;
    courseEndTime: string;
    courseOldEndTime: string;
    infinityCourseTime: string;
    courseDuration: number;
    courseBookingNote: string;
    change: boolean;
    roleArr: Array<any>;
    tagArr: Array<any>;
    rolePriceArr: Array<any>;
    places: Array<any>;
    selectedPlace: string;
    courseImgProgress: number;
    previewUrl: null | string;
    courseImgRemoved: boolean;
    infinityStartDate: string;
    hasExpireDate: boolean;
    infinityEndDate: string;
    weekDay: string;
    weekDaySection: string;
    dateUnit: string;
    noticePeriod: number;
    showDates: boolean;
    dates: Array<any>;
    oldDates: Array<any>;
    errorMessage: string | null;
    coursesSelectedDays: Array<string>;
    errorMessageDelete: null | string;
    deleteLoading: boolean;
    deleteCoursesInRowLoading: boolean;
    errorMessageDeleteCoursesInRow: string | null;
    redirect: null | string;
    showAddRolePricing: boolean;
    searchTextPriceRole: string;
    showConfirmationModal: boolean;
    hasCancellationAllowed: boolean;
    cancellationDays: number;
    negativeDates: Array<any>;
    sectorEndDate: any;
    sectorStartDate: any;
    editSectorErrMsg: string | null;
    selectedDates: Array<string>;
    setFollowUpImage: boolean;
    setFollowUpName: boolean;
    setFollowUpDescription: boolean;
    setFollowUpLocation: boolean;
    setFollowUpPlaces: boolean;
    setFollowUpRoles: boolean;
    setFollowUpManageRoles: boolean;
    setFollowUpPrice: boolean;
    setFollowUpBookingnote: boolean;
    setFollowUpCancellation: boolean;
    setBookableFrom: boolean;
    setVisibleFrom: boolean;
    setTags: boolean;
    setAllowBookForFamily: boolean;
    updateInRowLoading: boolean;
    errorMessageUpdateCoursesInRow: string | null;
    hasVisibleFromAllowed: boolean;
    visibleDays: number;
    hasBookableFromAllowed: boolean;
    bookableDays: number;
    showConfirmModalDeleteInRow: boolean;
    deleteCoursesArr: Array<any>;
    hasMinAge: boolean;
    hasMaxAge: boolean;
    minAge: number;
    maxAge: number;
    setMinAge: boolean;
    setMaxAge: boolean;
    showNotificationModal: boolean;
    updateNotoficationsLoading: boolean;
    allowBookForFamily: boolean;
    branchRoleArr: Array<string>;
    selectedForm: string;
    forms: any[];
  }
> {
  static contextType = MainContext;
  private wrapperRef: any;
  private calendarRef: any;

  constructor(props: ComposedProps) {
    super(props);
    this.wrapperRef = React.createRef();
    this.calendarRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseId: this.props.match.params.courseId,
      branchLoaded: false,
      courseLoaded: false,
      updateLoading: false,
      branch: {},
      course: {},
      courseTitle: "",
      courseDescription: "",
      coursePlaces: 0,
      courseDate: "",
      courseOldDate: "",
      courseEndDate: "",
      courseOldEndDate: "",
      courseTime: "",
      courseOldTime: "",
      courseEndTime: "",
      courseOldEndTime: "",
      infinityCourseTime: "",
      courseDuration: 60,
      courseBookingNote: "",
      change: false,
      roleArr: [],
      tagArr: [],
      rolePriceArr: [],
      places: [],
      selectedPlace: "",
      courseImgProgress: 0,
      previewUrl: null,
      courseImgRemoved: false,
      infinityStartDate: "",
      hasExpireDate: false,
      infinityEndDate: "",
      weekDay: "1",
      weekDaySection: "7",
      dateUnit: "3",
      noticePeriod: 1,
      showDates: false,
      dates: [],
      oldDates: [],
      errorMessage: null,
      coursesSelectedDays: [],
      errorMessageDelete: null,
      deleteLoading: false,
      deleteCoursesInRowLoading: false,
      errorMessageDeleteCoursesInRow: "",
      redirect: null,
      showAddRolePricing: false,
      searchTextPriceRole: "",
      showConfirmationModal: false,
      hasCancellationAllowed: false,
      cancellationDays: 0,
      negativeDates: [],
      sectorEndDate: null,
      sectorStartDate: null,
      editSectorErrMsg: null,
      selectedDates: [],
      setFollowUpImage: false,
      setFollowUpName: false,
      setFollowUpDescription: false,
      setFollowUpLocation: false,
      setFollowUpPlaces: false,
      setFollowUpRoles: false,
      setFollowUpManageRoles: false,
      setFollowUpPrice: false,
      setFollowUpBookingnote: false,
      setFollowUpCancellation: false,
      setBookableFrom: false,
      setVisibleFrom: false,
      setTags: false,
      setAllowBookForFamily: false,
      updateInRowLoading: false,
      errorMessageUpdateCoursesInRow: null,
      hasVisibleFromAllowed: false,
      visibleDays: 0,
      hasBookableFromAllowed: false,
      bookableDays: 0,
      showConfirmModalDeleteInRow: false,
      deleteCoursesArr: [],
      hasMinAge: false,
      hasMaxAge: false,
      minAge: 0,
      maxAge: 0,
      setMinAge: false,
      setMaxAge: false,
      showNotificationModal: false,
      updateNotoficationsLoading: false,
      allowBookForFamily: false,
      branchRoleArr: [],
      selectedForm: "",
      forms: [],
    };
  }

  componentDidMount = async () => {
    await Promise.all([this.requestBranch(), this.requestCourse()]).then(() => {
      this.handleTagArr();
      this.handleRoleArr();
      this.removeDeletedLocations();
    });
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  removeDeletedLocations = () => {
    let dates = this.state.dates;
    // remove deleted Locations
    const branchLocations = Object.keys(this.state.branch.locations);
    dates = dates.map((currDate: any) => {
      if (!branchLocations.includes(currDate.location)) {
        currDate.location = null;
      }
      return currDate;
    });
    this.setState({ dates });
  };

  handleClickOutside(event: any) {
    if (this.state.showAddRolePricing) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.hideAddRolePricingWindow();
      }
    }
  }

  hideAddRolePricingWindow = () => {
    this.setState({ showAddRolePricing: false });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      const places: Array<any> = [];
      Object.keys(branch.locations).forEach((locationId: string) => {
        places.push({
          id: locationId,
          name: branch.locations[locationId].name,
        });
      });
      places.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );

      const forms = Object.keys(branch.forms).map((formId: string) => {
        return {
          id: formId,
          name: branch.forms[formId].name,
        };
      });

      forms.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );

      this.setState({
        branch: branch,
        places,
        forms,
      });
    }
  };

  requestCourse = async () => {
    await fetchRetry(
      "getCourseFromIdFNew",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleCourseSuccess)
      .catch(this.handleError);
  };

  handleCourseSuccess = ({ data }: any) => {
    this.setState({ courseLoaded: true });
    if (data.success) {
      const courseData = data.data[0];
      const startTime = firebaseDateToDateObj(courseData.startTime);
      const endTime = firebaseDateToDateObj(courseData.endTime);
      const dates = courseData.courses
        ? courseData.courses.map((course: any) => {
            return {
              location: course.location.id,
              date: firebaseDateToDateObj(course.startTime),
              dateStr: dateToStr(firebaseDateToDateObj(course.startTime)),
              time: dateToTimeStr(firebaseDateToDateObj(course.startTime)),
              endDateStr: dateToStr(firebaseDateToDateObj(course.endTime)),
              endTime: dateToTimeStr(firebaseDateToDateObj(course.endTime)),
            };
          })
        : [];
      const coursesSelectedDays = courseData.courses
        ? courseData.courses.map((course: any) => {
            return dateToStr(firebaseDateToDateObj(course.startTime));
          })
        : [];

      this.setState(
        {
          selectedPlace: courseData?.location?.id
            ? courseData?.location?.id
            : "",
          course: courseData,
          courseTitle: courseData.title,
          courseDescription: courseData.description,
          coursePlaces: courseData.places,
          courseDate: dateToStrInput(startTime),
          courseOldDate: dateToStrInput(startTime),
          courseTime: dateToTimeStr(startTime),
          courseOldTime: dateToTimeStr(startTime),
          courseEndDate: dateToStrInput(endTime),
          courseOldEndDate: dateToStrInput(endTime),
          courseEndTime: dateToTimeStr(endTime),
          courseOldEndTime: dateToTimeStr(endTime),
          courseDuration: courseData.duration ? courseData.duration : 60,
          courseBookingNote: courseData.bookingMailMessage,
          dates,
          oldDates: JSON.parse(JSON.stringify(dates)),
          selectedDates: coursesSelectedDays,
          infinityStartDate: dateToStrInput(
            firebaseDateToDateObj(courseData.startTime)
          ),
          hasExpireDate: courseData.endTime !== null,
          infinityEndDate: courseData.endTime
            ? dateToStrInput(firebaseDateToDateObj(courseData.endTime))
            : "",
          noticePeriod: courseData.noticePeriod,
          dateUnit:
            dateUnitsArr.indexOf(courseData.noticePeriodDateUnit) + 1 + "",
          infinityCourseTime: courseData.time,
          cancellationDays:
            courseData.cancellationDays !== null
              ? courseData.cancellationDays
              : 0,
          hasCancellationAllowed: courseData.cancellationDays !== null,
          visibleDays:
            courseData.visibleDays !== null ? courseData.visibleDays : 0,
          hasVisibleFromAllowed: courseData.visibleDays !== null,
          bookableDays:
            courseData.bookableDays !== null ? courseData.bookableDays : 0,
          hasBookableFromAllowed: courseData.bookableDays !== null,
          negativeDates: courseData.negativeDates
            ? courseData.negativeDates.slice()
            : [],
          weekDay: courseData.weekDay,
          hasMinAge: courseData.minAge !== null,
          hasMaxAge: courseData.maxAge !== null,
          minAge: courseData.minAge ? courseData.minAge : 0,
          maxAge: courseData.maxAge ? courseData.maxAge : 0,
          allowBookForFamily: !!courseData.allowBookForFamily,
          selectedForm: courseData.form?.id || "",
        },
        this.checkChange
      );
    }
  };

  showErrorMsg = () => {
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
  };

  handleError = (err: any) => {
    this.showErrorMsg();
    console.error(err);
  };

  deleteCourseImg = () => {
    fetchRetry(
      "removeCourseImg",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
      },
      1,
      5
    )
      .then(this.handleRemoveCourseImgSuccess)
      .catch(this.handleError);
  };

  handleRemoveCourseImgSuccess = ({ data }: any) => {
    if (data.success) {
      this.setState({ courseImgRemoved: true });
    }
  };

  updateCourse = () => {
    if (!this.state.updateLoading) {
      const priceArr = this.state.rolePriceArr
        .filter((rolePrice: any) => rolePrice.selected)
        .map((rolePrice: any) => {
          return {
            id: rolePrice.id,
            price: rolePrice.val * 100,
            showAlways: rolePrice.showAlways,
          };
        });
      const roleIds: Array<string> = this.state.roleArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      const tagIds: Array<string> = this.state.tagArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      this.setState({ updateLoading: true });
      const manageRoleIds: Array<string> = this.state.branchRoleArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      if (this.state.course.courseType === "single-course") {
        const startDateObj = removeTimeZoneOffset(
          stringDateAndTimeToDate(
            reformatDate(this.state.courseDate),
            this.state.courseTime
          )
        );
        const endDateObj = removeTimeZoneOffset(
          stringDateAndTimeToDate(
            reformatDate(this.state.courseEndDate),
            this.state.courseEndTime
          )
        );

        fetchRetry(
          "updateCourseNew",
          {
            clubId: this.state.clubId,
            branchId: this.state.branchId,
            courseId: this.state.courseId,
            courseType: "single-course",
            title: this.state.courseTitle,
            description: this.state.courseDescription,
            bookingMailMessage: this.state.courseBookingNote,
            date: dateToStr(startDateObj),
            time: dateToTimeStr(startDateObj),
            endDate: dateToStr(endDateObj),
            endTime: dateToTimeStr(endDateObj),
            locationId: this.state.selectedPlace
              ? this.state.selectedPlace
              : null,
            places: this.state.coursePlaces,
            allowedRoles: roleIds,
            tags: tagIds,
            priceArr,
            cancellationDays: this.state.hasCancellationAllowed
              ? this.state.cancellationDays
              : null,
            visibleDays: this.state.hasVisibleFromAllowed
              ? this.state.visibleDays
              : null,
            bookableDays: this.state.hasBookableFromAllowed
              ? this.state.bookableDays
              : null,
            minAge: this.state.hasMinAge ? this.state.minAge : null,
            maxAge: this.state.hasMaxAge ? this.state.maxAge : null,
            allowBookForFamily: this.state.allowBookForFamily,
            manageRoles: manageRoleIds,
            formId: this.state.selectedForm || null,
          },
          1,
          5
        )
          .then(this.handleUpdateCourseSuccess)
          .catch(this.handleError);
      } else if (this.state.course.courseType === "block-course") {
        const courses = this.state.dates.map((date: any) => {
          const startDateObj = removeTimeZoneOffset(
            stringDateAndTimeToDate(date.dateStr, date.time)
          );
          const endDateObj = removeTimeZoneOffset(
            stringDateAndTimeToDate(date.endDateStr, date.endTime)
          );
          return {
            title: this.state.courseTitle,
            description: this.state.courseDescription,
            date: dateToStr(startDateObj),
            time: dateToTimeStr(startDateObj),
            endDate: dateToStr(endDateObj),
            endTime: dateToTimeStr(endDateObj),
            locationId: date.location ? date.location : null,
          };
        });

        fetchRetry(
          "updateCourseNew",
          {
            clubId: this.state.clubId,
            branchId: this.state.branchId,
            courseId: this.state.courseId,
            courseType: "block-course",
            title: this.state.courseTitle,
            description: this.state.courseDescription,
            bookingMailMessage: this.state.courseBookingNote,
            places: this.state.coursePlaces,
            allowedRoles: roleIds,
            tags: tagIds,
            courses: courses,
            priceArr,
            cancellationDays: this.state.hasCancellationAllowed
              ? this.state.cancellationDays
              : null,
            visibleDays: this.state.hasVisibleFromAllowed
              ? this.state.visibleDays
              : null,
            bookableDays: this.state.hasBookableFromAllowed
              ? this.state.bookableDays
              : null,
            minAge: this.state.hasMinAge ? this.state.minAge : null,
            maxAge: this.state.hasMaxAge ? this.state.maxAge : null,
            allowBookForFamily: this.state.allowBookForFamily,
            manageRoles: manageRoleIds,
            formId: this.state.selectedForm || null,
          },
          1,
          5
        )
          .then(this.handleUpdateCourseSuccess)
          .catch(this.handleError);
      } else if (this.state.course.courseType === "infinity-course") {
        fetchRetry(
          "updateCourseNew",
          {
            clubId: this.state.clubId,
            branchId: this.state.branchId,
            courseId: this.state.courseId,
            courseType: "infinity-course",
            weekDay: parseInt(this.state.weekDay),
            locationId: this.state.selectedPlace
              ? this.state.selectedPlace
              : null,
            date: reformatDate(this.state.infinityStartDate),
            endDate: this.state.hasExpireDate
              ? reformatDate(this.state.infinityEndDate)
              : null,
            time: this.state.infinityCourseTime,
            duration: this.state.courseDuration,
            title: this.state.courseTitle,
            description: this.state.courseDescription,
            bookingMailMessage: this.state.courseBookingNote,
            places: this.state.coursePlaces,
            allowedRoles: roleIds,
            tags: tagIds,
            noticePeriodDateUnit:
              dateUnits[parseInt(this.state.dateUnit) - 1].dbName,
            noticePeriod: this.state.noticePeriod,
            priceArr,
            negativeDates: this.state.negativeDates,
            visibleDays: this.state.hasVisibleFromAllowed
              ? this.state.visibleDays
              : null,
            bookableDays: this.state.hasBookableFromAllowed
              ? this.state.bookableDays
              : null,
            minAge: this.state.hasMinAge ? this.state.minAge : null,
            maxAge: this.state.hasMaxAge ? this.state.maxAge : null,
            allowBookForFamily: this.state.allowBookForFamily,
            manageRoles: manageRoleIds,
            formId: this.state.selectedForm || null,
          },
          1,
          5
        )
          .then(this.handleUpdateCourseSuccess)
          .catch(this.handleError);
      }
    }
  };

  handleUpdateCourseSuccess = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.setState({ errorMessage: null });
      this.requestCourse();
    } else {
      this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  updateCoursesInRow = () => {
    if (!this.state.updateInRowLoading) {
      const priceArr = this.state.rolePriceArr
        .filter((rolePrice: any) => rolePrice.selected)
        .map((rolePrice: any) => {
          return {
            id: rolePrice.id,
            price: rolePrice.val * 100,
          };
        });
      const roleIds: Array<string> = this.state.roleArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      const manageRoleIds: Array<string> = this.state.branchRoleArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      const tagIds: Array<string> = this.state.tagArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      this.setState({ updateInRowLoading: true });
      const updateVal: any = {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        followId: this.state.course.followId,
        updateCourseImage: this.state.setFollowUpImage,
      };
      if (this.state.setFollowUpName) {
        updateVal.title = this.state.courseTitle;
      }
      if (this.state.setFollowUpDescription) {
        updateVal.description = this.state.courseDescription;
      }
      if (this.state.setFollowUpBookingnote) {
        updateVal.bookingMailMessage = this.state.courseBookingNote;
      }
      if (this.state.setFollowUpLocation) {
        updateVal.locationId = this.state.selectedPlace;
      }
      if (this.state.setFollowUpRoles) {
        updateVal.allowedRoles = roleIds;
      }
      if (this.state.setFollowUpManageRoles) {
        updateVal.manageRoles = manageRoleIds;
      }
      if (this.state.setFollowUpPrice) {
        updateVal.priceArr = priceArr;
      }
      if (this.state.setTags) {
        updateVal.tags = tagIds;
      }
      if (this.state.setFollowUpCancellation) {
        updateVal.cancellationDays = this.state.hasCancellationAllowed
          ? this.state.cancellationDays
          : null;
      }
      if (this.state.setBookableFrom) {
        updateVal.bookableDays = this.state.hasBookableFromAllowed
          ? this.state.bookableDays
          : null;
      }
      if (this.state.setVisibleFrom) {
        updateVal.visibleDays = this.state.hasVisibleFromAllowed
          ? this.state.visibleDays
          : null;
      }
      if (this.state.setFollowUpName) {
        updateVal.title = this.state.courseTitle;
      }
      if (this.state.setMinAge) {
        updateVal.minAge = this.state.hasMinAge ? this.state.minAge : null;
      }
      if (this.state.setMaxAge) {
        updateVal.maxAge = this.state.hasMaxAge ? this.state.maxAge : null;
      }
      if (this.state.setAllowBookForFamily) {
        updateVal.allowBookForFamily = this.state.allowBookForFamily;
      }
      fetchRetry("updateCoursesInRowNew", updateVal, 1, 5)
        .then(this.handleUpdateCourseInRowSuccess)
        .catch(this.handleError);
    }
  };

  handleUpdateCourseInRowSuccess = ({ data }: any) => {
    this.setState({ updateInRowLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Die ausgewählten Einstellungen wurden für alle Kurse in dieser Reihe übernommen.",
        "success",
        4
      );
      this.setState({
        errorMessageUpdateCoursesInRow: null,
        setFollowUpBookingnote: false,
        setFollowUpCancellation: false,
        setFollowUpDescription: false,
        setFollowUpImage: false,
        setFollowUpLocation: false,
        setFollowUpName: false,
        setFollowUpPlaces: false,
        setFollowUpPrice: false,
        setFollowUpRoles: false,
        setFollowUpManageRoles: false,
        setBookableFrom: false,
        setTags: false,
        setMinAge: false,
        setMaxAge: false,
        setAllowBookForFamily: false,
      });
    } else {
      this.setState({ errorMessageUpdateCoursesInRow: data.errorMsgDe });
    }
  };

  handleDeleteCourse = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleDeleteCourseConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteCourse",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        force: true,
      },
      1,
      5
    )
      .then(this.handleDeleteCourseSuccess)
      .catch(this.handleError);
  };

  handleDeleteCourseSuccess = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Der Kurs wurde erfolgreich gelöscht.",
        "success",
        4
      );
      this.setState({
        errorMessageDelete: null,
        redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course`,
      });
    } else {
      this.setState({
        errorMessageDelete: data.errorMsgDe,
      });
    }
  };

  handleTagArr = () => {
    if (this.state.branchLoaded && this.state.course) {
      const tagArr = this.state.branch.tags
        ? Object.keys(this.state.branch.tags).map((tagId: string) => {
            const currBranchRole = this.state.branch.tags[tagId];
            return {
              ...currBranchRole,
              ...{
                selected: this.state.course?.tags?.includes(tagId),
                id: tagId,
                color: "#5DADE2",
              },
            };
          })
        : [];
      this.setState({ tagArr });
    }
  };

  handleRoleArr = () => {
    if (this.state.branchLoaded && this.state.courseLoaded) {
      const branchRoleArr = Object.keys(this.state.branch.roles)
        .filter(
          (roleId: string) => this.state.branch?.roles[roleId].type !== "club"
        )
        .map((roleId: string) => {
          const currBranchRole = this.state.branch?.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: this.state.course?.manageRoles?.includes(roleId),
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        });
      const roleArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          const currBranchRole = this.state.branch.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: this.state.course.allowedRoles.includes(roleId),
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        }
      );
      roleArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        color: "#ffd025",
        selected: this.state.course.allowedRoles.includes("public"),
      });
      roleArr.push({
        custom: false,
        default: false,
        id: "guest",
        name: "Jeder",
        selected: this.state.course.allowedRoles.includes("guest"),
        color: "#ffd025",
      });

      const rolePriceArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          const currBranchRole = this.state.branch.roles[roleId];
          const index = this.state.course.priceArr.findIndex(
            (role: any) => role.id === roleId
          );
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: index !== -1,
              val:
                index !== -1
                  ? this.state.course.priceArr[index].price / 100
                  : 0,
              showAlways:
                index !== -1
                  ? this.state.course.priceArr[index].showAlways
                  : false,
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        }
      );
      const indexPublic = this.state.course.priceArr.findIndex(
        (role: any) => role.id === "public"
      );
      rolePriceArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        selected: indexPublic !== -1,
        color: "#ffd025",
        val:
          indexPublic !== -1
            ? this.state.course.priceArr[indexPublic].price / 100
            : 0,
        priority: -1,
        showAlways:
          indexPublic !== -1
            ? this.state.course.priceArr[indexPublic].showAlways
            : false,
      });

      const indexDefault = this.state.course.priceArr.findIndex(
        (role: any) => role.id === "default"
      );
      rolePriceArr.push({
        custom: false,
        default: true,
        id: "default",
        name: "Basis",
        selected: indexDefault !== -1,
        color: "#9e9e9e",
        val:
          indexDefault !== -1
            ? this.state.course.priceArr[indexDefault].price / 100
            : 0,
        priority: -3,
        showAlways:
          indexDefault !== -1
            ? this.state.course.priceArr[indexDefault].showAlways
            : false,
      });
      rolePriceArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));
      this.setState(
        {
          roleArr,
          rolePriceArr,
          branchRoleArr,
        },
        this.checkChange
      );
    }
  };

  handleChangeSearchTextPriceRole = (val: any) => {
    this.setState({ searchTextPriceRole: val.target.value });
  };

  handlePriceRoleChange = (id: number, val: any) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].val = Math.floor(val * 100) / 100;
    this.setState({ rolePriceArr }, this.checkChange);
  };

  handleShowAlwaysChange = (id: number) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].showAlways = !rolePriceArr[index].showAlways;
    this.setState({ rolePriceArr }, this.checkChange);
  };

  handleAddPriceRole = () => {
    this.setState({ showAddRolePricing: true });
  };

  handleRolePriceSelect = (id: string) => {
    const rolePriceArr: any = this.state.rolePriceArr;
    const index = findWithAttr(rolePriceArr, "id", id);
    rolePriceArr[index].selected = true;
    rolePriceArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));
    this.setState({ rolePriceArr }, this.checkChange);
  };

  handleRolePriceUnselect = (id: string) => {
    const rolePriceArr: any = this.state.rolePriceArr;
    const index = findWithAttr(rolePriceArr, "id", id);
    rolePriceArr[index].selected = false;
    this.setState({ rolePriceArr }, this.checkChange);
  };

  handleUpdateTitle = (val: string) => {
    this.setState({ courseTitle: val }, this.checkChange);
  };

  handleUpdateDescription = (val: string) => {
    this.setState({ courseDescription: val }, this.checkChange);
  };

  handleUpdatePlaces = (val: number) => {
    this.setState({ coursePlaces: val }, this.checkChange);
  };

  handleDateChange = (val: any) => {
    this.setState({ courseDate: val.target.value }, this.checkChange);
  };

  handleEndDateChange = (val: any) => {
    this.setState({ courseEndDate: val.target.value }, this.checkChange);
  };

  handleInfinityTimeChange = (val: any) => {
    this.setState({ infinityCourseTime: val.target.value }, this.checkChange);
  };

  handleTimeChange = (val: any) => {
    this.setState({ courseTime: val.target.value }, this.checkChange);
  };

  handleEndTimeChange = (val: any) => {
    this.setState({ courseEndTime: val.target.value }, this.checkChange);
  };

  handleUpdateDuration = (val: number) => {
    this.setState({ courseDuration: val }, this.checkChange);
  };

  handlePlaceChange = (val: any) => {
    this.setState({ selectedPlace: val }, this.checkChange);
  };
  handleFormChange = (val: any) => {
    this.setState({ selectedForm: val }, this.checkChange);
  };

  handleBookingNoteChange = (val: any) => {
    this.setState({ courseBookingNote: val }, this.checkChange);
  };

  toggleHasExpireDate = () => {
    this.setState(
      { hasExpireDate: !this.state.hasExpireDate },
      this.checkChange
    );
  };

  toggleVisibleFromAllowed = () => {
    this.setState(
      {
        hasVisibleFromAllowed: !this.state.hasVisibleFromAllowed,
      },
      this.checkChange
    );
  };

  toggleBookableFromAllowed = () => {
    this.setState(
      {
        hasBookableFromAllowed: !this.state.hasBookableFromAllowed,
      },
      this.checkChange
    );
  };

  handleInfinityStartDateChange = (date: string) => {
    this.setState({ infinityStartDate: date }, this.checkChange);
  };

  handleInfinityEndDateChange = (date: string) => {
    this.setState({ infinityEndDate: date }, this.checkChange);
  };

  handleDateUnitsChange = (val: any) => {
    this.setState({ dateUnit: val }, this.checkChange);
  };

  handleNoticePeriodChange = (val: any) => {
    this.setState({ noticePeriod: val }, this.checkChange);
  };

  handleWeekDayChange = (val: any) => {
    this.setState({ weekDay: val }, this.checkChange);
  };

  handleVisibleFromDaysChange = (val: any) => {
    this.setState({ visibleDays: val }, this.checkChange);
  };

  handleBookableFromChange = (val: any) => {
    this.setState({ bookableDays: val }, this.checkChange);
  };

  toggleShowDates = () => {
    this.setState({ showDates: !this.state.showDates });
  };

  onLocationChange = (index: number, location: string) => {
    let dates = this.state.dates;
    dates[index].location = location;
    this.setState({ dates }, this.checkChange);
  };

  onTimeChange = (index: number, time: string) => {
    let dates = this.state.dates;
    dates[index].time = time;
    this.setState({ dates }, this.checkChange);
  };

  onEndTimeChange = (index: number, time: string) => {
    let dates = this.state.dates;
    dates[index].endTime = time;
    this.setState({ dates }, this.checkChange);
  };

  onEndDateChange = (index: number, endDate: string) => {
    let dates = this.state.dates;
    dates[index].endDateStr = reformatDate(endDate);
    this.setState({ dates }, this.checkChange);
  };

  onSelectedDatesChange = (selectedDates: Array<string>) => {
    // filter dates - remove unselected ones
    let dates: any = this.state.dates.filter((date: any) =>
      selectedDates.includes(date.dateStr)
    );
    const dateStrArr = dates.map((date: any) => date.dateStr);
    // add new dates
    selectedDates.forEach((date: string) => {
      if (!dateStrArr.includes(date)) {
        const startDate = strToDateWithMinutes(
          date,
          strTimeToMin(this.state.courseTime)
        );
        const startTime = dateToTimeStr(startDate);
        const endDate = addMinutesToDate(startDate, this.state.courseDuration);
        const endTime = dateToTimeStr(endDate);
        dates.push({
          dateStr: date,
          endDateStr: dateToStr(endDate),
          date: startDate,
          time: startTime,
          endTime,
          name: this.state.courseTitle,
          description: this.state.courseDescription,
          location: this.state.selectedPlace,
        });
      }
    });
    // sort dates
    dates.sort((a: any, b: any) => a.date.getTime() - b.date.getTime());
    this.setState({ dates, selectedDates }, this.checkChange);
  };

  onSelectedNegativeDatesChange = (selectedDates: Array<string>) => {
    this.setState({ negativeDates: selectedDates }, this.checkChange);
  };

  checkChange = () => {
    // check if the valid roles have changed
    let validRolesArrChange = false;
    const oldValidRolesArr = this.state.course.allowedRoles;
    const newValidRolesObj = this.state.roleArr.filter(
      (item: any) => item.selected
    );
    if (newValidRolesObj.length !== oldValidRolesArr.length) {
      validRolesArrChange = true;
    } else {
      newValidRolesObj.forEach((item: any) => {
        if (!oldValidRolesArr.includes(item.id)) {
          validRolesArrChange = true;
        }
      });
    }
    ///
    // check if the manage roles have changed
    let validManageRolesArrChange = false;
    const oldManageValidRolesArr = this.state.course.manageRoles || [];
    const newManageValidRolesObj = this.state.branchRoleArr.filter(
      (item: any) => item.selected
    );
    if (newManageValidRolesObj.length !== oldManageValidRolesArr.length) {
      validManageRolesArrChange = true;
    } else {
      newManageValidRolesObj.forEach((item: any) => {
        if (!oldManageValidRolesArr.includes(item.id)) {
          validManageRolesArrChange = true;
        }
      });
    }
    ///
    // check if the tags have changed
    let tagsArrChange = false;
    const oldValidTagArr = this.state.course.tags;
    const newValidTagObj = this.state.tagArr.filter(
      (item: any) => item.selected
    );
    if (newValidTagObj.length !== oldValidTagArr.length) {
      tagsArrChange = true;
    } else {
      newValidTagObj.forEach((item: any) => {
        if (!oldValidTagArr.includes(item.id)) {
          tagsArrChange = true;
        }
      });
    }
    ///
    // check if the price has changed
    let priceChange = false;
    const priceArrActive = this.state.rolePriceArr.filter(
      (priceRole: any) => priceRole.selected
    );
    const priceArrIds = priceArrActive.map((currPrice: any) => currPrice.id);
    if (priceArrActive.length !== this.state.course.priceArr.length) {
      priceChange = true;
    } else {
      this.state.course.priceArr.forEach((currPrice: any) => {
        const roleId = currPrice.id;
        if (!priceArrIds.includes(roleId)) {
          priceChange = true;
        } else {
          const index = priceArrActive.findIndex(
            (role: any) => role.id === roleId
          );
          if (
            currPrice.price !== priceArrActive[index].val * 100 ||
            currPrice.showAlways !== priceArrActive[index].showAlways
          ) {
            priceChange = true;
          }
        }
      });
    }
    ///
    if (this.state.course.courseType === "single-course") {
      this.setState({
        change:
          this.state.courseTitle !== this.state.course.title ||
          this.state.courseDescription !== this.state.course.description ||
          !!this.state.selectedPlace !== !!this.state.course.location.id ||
          !!this.state.selectedForm !== !!this.state.course?.form?.id ||
          this.state.coursePlaces !== this.state.course.places ||
          this.state.courseTime !== this.state.courseOldTime ||
          this.state.courseDate !== this.state.courseOldDate ||
          this.state.courseEndTime !== this.state.courseOldEndTime ||
          this.state.courseEndDate !== this.state.courseOldEndDate ||
          this.state.courseBookingNote !==
            this.state.course.bookingMailMessage ||
          this.state.hasCancellationAllowed !==
            (this.state.course.cancellationDays !== null) ||
          (this.state.hasCancellationAllowed &&
            this.state.cancellationDays !==
              this.state.course.cancellationDays) ||
          this.state.hasVisibleFromAllowed !==
            (this.state.course.visibleDays !== null) ||
          (this.state.hasVisibleFromAllowed &&
            this.state.visibleDays !== this.state.course.visibleDays) ||
          this.state.hasBookableFromAllowed !==
            (this.state.course.bookableDays !== null) ||
          (this.state.hasBookableFromAllowed &&
            this.state.bookableDays !== this.state.course.bookableDays) ||
          this.state.hasMinAge !==
            (this.state.course.minAge !== null &&
              this.state.course.minAge !== undefined) ||
          this.state.hasMaxAge !==
            (this.state.course.maxAge !== null &&
              this.state.course.maxAge !== undefined) ||
          (this.state.hasMinAge &&
            this.state.minAge !== this.state.course.minAge) ||
          (this.state.hasMaxAge &&
            this.state.maxAge !== this.state.course.maxAge) ||
          validRolesArrChange ||
          validManageRolesArrChange ||
          priceChange ||
          tagsArrChange ||
          this.state.allowBookForFamily !==
            !!this.state.course.allowBookForFamily,
      });
    } else if (this.state.course.courseType === "block-course") {
      // check if the dates has changed
      let dateChange = false;
      if (
        Object.keys(this.state.dates).length !==
        Object.keys(this.state.oldDates).length
      ) {
        dateChange = true;
      } else {
        this.state.dates.forEach((date: any) => {
          const index = this.state.oldDates.findIndex(
            (currDate: any) =>
              strToDate(currDate.dateStr).getTime() ===
                strToDate(date.dateStr).getTime() &&
              strToDate(currDate.endDateStr).getTime() ===
                strToDate(date.endDateStr).getTime() &&
              currDate.time === date.time &&
              currDate.endTime === date.endTime &&
              currDate.location === date.location
          );
          if (index === -1) {
            dateChange = true;
          }
        });
      }

      ///
      this.setState({
        change:
          this.state.courseTitle !== this.state.course.title ||
          this.state.courseDescription !== this.state.course.description ||
          this.state.coursePlaces !== this.state.course.places ||
          this.state.courseTime !== this.state.courseOldTime ||
          this.state.courseDate !== this.state.courseOldDate ||
          this.state.courseEndTime !== this.state.courseOldEndTime ||
          this.state.courseEndDate !== this.state.courseOldEndDate ||
          !!this.state.selectedForm !== !!this.state.course?.form?.id ||
          this.state.courseBookingNote !==
            this.state.course.bookingMailMessage ||
          this.state.hasCancellationAllowed !==
            (this.state.course.cancellationDays !== null) ||
          (this.state.hasCancellationAllowed &&
            this.state.cancellationDays !==
              this.state.course.cancellationDays) ||
          this.state.hasVisibleFromAllowed !==
            (this.state.course.visibleDays !== null) ||
          (this.state.hasVisibleFromAllowed &&
            this.state.visibleDays !== this.state.course.visibleDays) ||
          this.state.hasBookableFromAllowed !==
            (this.state.course.bookableDays !== null) ||
          (this.state.hasBookableFromAllowed &&
            this.state.bookableDays !== this.state.course.bookableDays) ||
          this.state.hasMinAge !==
            (this.state.course.minAge !== null &&
              this.state.course.minAge !== undefined) ||
          this.state.hasMaxAge !==
            (this.state.course.maxAge !== null &&
              this.state.course.maxAge !== undefined) ||
          (this.state.hasMinAge &&
            this.state.minAge !== this.state.course.minAge) ||
          (this.state.hasMaxAge &&
            this.state.maxAge !== this.state.course.maxAge) ||
          validRolesArrChange ||
          validManageRolesArrChange ||
          priceChange ||
          dateChange ||
          tagsArrChange ||
          this.state.allowBookForFamily !==
            !!this.state.course.allowBookForFamily,
      });
    } else if (this.state.course.courseType === "infinity-course") {
      // check if the negative days have changes
      let negativeDaysChange = false;
      if (
        this.state.negativeDates.length !==
        this.state.course.negativeDates.length
      ) {
        negativeDaysChange = true;
      } else {
        this.state.negativeDates.forEach((currDate: string) => {
          if (!this.state.course.negativeDates.includes(currDate)) {
            negativeDaysChange = true;
          }
        });
      }
      ///
      this.setState({
        change:
          this.state.courseTitle !== this.state.course.title ||
          this.state.courseDescription !== this.state.course.description ||
          this.state.coursePlaces !== this.state.course.places ||
          this.state.courseTime !== this.state.courseOldTime ||
          this.state.courseDate !== this.state.courseOldDate ||
          this.state.courseEndTime !== this.state.courseOldEndTime ||
          this.state.courseEndDate !== this.state.courseOldEndDate ||
          !!this.state.selectedForm !== !!this.state.course?.form?.id ||
          this.state.courseBookingNote !==
            this.state.course.bookingMailMessage ||
          this.state.hasCancellationAllowed !==
            (this.state.course.cancellationDays !== null) ||
          (this.state.hasCancellationAllowed &&
            this.state.cancellationDays !==
              this.state.course.cancellationDays) ||
          this.state.hasVisibleFromAllowed !==
            (this.state.course.visibleDays !== null) ||
          (this.state.hasVisibleFromAllowed &&
            this.state.visibleDays !== this.state.course.visibleDays) ||
          this.state.hasBookableFromAllowed !==
            (this.state.course.bookableDays !== null) ||
          (this.state.hasBookableFromAllowed &&
            this.state.bookableDays !== this.state.course.bookableDays) ||
          this.state.infinityCourseTime !== this.state.course.time ||
          this.state.courseDuration !== this.state.course.duration ||
          inputStrToDate(this.state.infinityStartDate).getTime() !==
            removeTimeZoneOffset(
              firebaseDateToDateObj(this.state.course.startTime)
            )?.getTime() ||
          this.state.hasExpireDate !== (this.state.course.endTime !== null) ||
          (this.state.hasExpireDate &&
            inputStrToDate(this.state.infinityEndDate).getTime() !==
              removeTimeZoneOffset(
                firebaseDateToDateObj(this.state.course.endTime)
              )?.getTime()) ||
          dateUnits[parseInt(this.state.dateUnit) - 1].dbName !==
            this.state.course.noticePeriodDateUnit ||
          this.state.noticePeriod !== this.state.course.noticePeriod ||
          this.state.hasMinAge !==
            (this.state.course.minAge !== null &&
              this.state.course.minAge !== undefined) ||
          this.state.hasMaxAge !==
            (this.state.course.maxAge !== null &&
              this.state.course.maxAge !== undefined) ||
          (this.state.hasMinAge &&
            this.state.minAge !== this.state.course.minAge) ||
          (this.state.hasMaxAge &&
            this.state.maxAge !== this.state.course.maxAge) ||
          validRolesArrChange ||
          validManageRolesArrChange ||
          priceChange ||
          negativeDaysChange ||
          this.state.course.weekDay !== parseInt(this.state.weekDay) ||
          tagsArrChange ||
          this.state.allowBookForFamily !==
            !!this.state.course.allowBookForFamily,
      });
    }
  };

  handleRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ roleArr: newRoleArr }, this.checkChange);
  };

  handleRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ roleArr: newRoleArr }, this.checkChange);
  };

  handleTagSelect = (bookingTypeId: string) => {
    const newTagArr: any = this.state.tagArr;
    const index = findWithAttr(newTagArr, "id", bookingTypeId);
    newTagArr[index].selected = true;
    this.setState({ tagArr: newTagArr }, this.checkChange);
  };

  handleTagUnselect = (bookingTypeId: string) => {
    const newTagArr: any = this.state.tagArr;
    const index = findWithAttr(newTagArr, "id", bookingTypeId);
    newTagArr[index].selected = false;
    this.setState({ tagArr: newTagArr }, this.checkChange);
  };

  handleImageUpload = (e: any) => {
    const file = e.target.files[0];
    const previewUrl = URL.createObjectURL(file);
    this.setState({ previewUrl });
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      console.error("the filetype is invalid, valid fileTypes are png/jpg");
    } else {
      const fileEnding = file.type === "image/png" ? ".png" : ".jpg";
      var storageRef = storage.ref(
        `images/users/${this.context.user?.uid}/courses/${this.state.courseId}/courseImg${fileEnding}`
      );
      // Uplaod file
      const task = storageRef.put(file);
      // Update progress bar
      task.on(
        "state_changed",
        (snapshot) => {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({ courseImgProgress: percentage });
        },
        (error) => {
          console.error("Error: ");
          console.error(error);
        },
        () => {
          this.setState({ courseImgRemoved: false });
        }
      );
    }
  };

  handleCancellationDaysChange = (val: any) => {
    this.setState({ cancellationDays: val }, this.checkChange);
  };

  toggleCancellationAllowed = () => {
    this.setState(
      {
        hasCancellationAllowed: !this.state.hasCancellationAllowed,
      },
      this.checkChange
    );
  };

  handleSectorStartDateChange = (date: Date) => {
    if (!this.state.sectorEndDate || date > this.state.sectorEndDate) {
      this.setState({
        sectorEndDate: date,
      });
    }
    this.setState({ sectorStartDate: date });
  };

  handleSectorEndDateChange = (date: Date) => {
    this.setState({ sectorEndDate: date });
  };

  addSector = () => {
    if (this.state.sectorStartDate && this.state.sectorEndDate) {
      if (this.state.sectorStartDate <= this.state.sectorEndDate) {
        let dates: Array<any> = this.state.dates;
        const sectorStartDate = inputStrToDate(this.state.sectorStartDate);
        const sectorEndDate = inputStrToDate(this.state.sectorEndDate);
        const deltaDays =
          (sectorEndDate.getTime() - sectorStartDate.getTime()) / 86400000 + 1;
        const selectedDates: Array<string> = this.state.selectedDates;

        for (var i = 0; i < deltaDays; i++) {
          let startDate = addDaysToDate(copyDate(sectorStartDate), i);
          startDate = strToDateWithMinutes(
            dateToStr(startDate),
            strTimeToMin(this.state.courseTime)
          );
          if (
            startDate.getDay() === parseInt(this.state.weekDaySection) ||
            parseInt(this.state.weekDaySection) === 7
          ) {
            if (i === 0) {
              this.calendarRef.current.handleSetMonth(
                startDate.getFullYear(),
                startDate.getMonth() + 1
              );
            }
            const endDate = addMinutesToDate(
              startDate,
              this.state.courseDuration
            );
            const endTime = dateToTimeStr(endDate);
            const dateStr = dateToStr(startDate);
            if (!selectedDates.includes(dateStr)) {
              selectedDates.push(dateToStr(startDate));
              dates.push({
                dateStr: dateToStr(startDate),
                endDateStr: dateToStr(endDate),
                date: startDate,
                time: this.state.courseTime,
                endTime,
                name: this.state.courseTitle,
                description: this.state.courseDescription,
                location: this.state.selectedPlace,
              });
            }
          }
        }
        dates.sort((a: any, b: any) =>
          a.date.getTime() > b.date.getTime() ? 1 : -1
        );
        this.setState(
          { dates, selectedDates, editSectorErrMsg: null },
          this.checkChange
        );
      } else {
        this.setState({
          editSectorErrMsg: "Das Startdatum darf nicht nach dem Enddatum sein.",
        });
      }
    } else {
      this.setState({
        editSectorErrMsg: "Bitte wähle ein gültiges Start-und Enddatum aus.",
      });
    }
  };

  removeSector = () => {
    if (this.state.sectorStartDate && this.state.sectorEndDate) {
      if (this.state.sectorStartDate <= this.state.sectorEndDate) {
        let dates: Array<any> = this.state.dates;
        const sectorStartDate = inputStrToDate(this.state.sectorStartDate);
        const sectorEndDate = inputStrToDate(this.state.sectorEndDate);
        const deltaDays =
          (sectorEndDate.getTime() - sectorStartDate.getTime()) / 86400000 + 1;
        let selectedDates: Array<string> = this.state.selectedDates;
        for (var i = 0; i < deltaDays; i++) {
          let startDate = addDaysToDate(copyDate(sectorStartDate), i);
          if (
            startDate.getDay() === parseInt(this.state.weekDaySection) ||
            parseInt(this.state.weekDaySection) === 7
          ) {
            const dateStr = dateToStr(startDate);
            selectedDates = selectedDates.filter(
              (currDate: string) => currDate !== dateStr
            );
            dates = dates.filter(
              (currDate: any) => currDate.dateStr !== dateStr
            );
          }
        }
        this.setState(
          { dates, selectedDates, editSectorErrMsg: null },
          this.checkChange
        );
      } else {
        this.setState({
          editSectorErrMsg: "Das Startdatum darf nicht nach dem Enddatum sein.",
        });
      }
    } else {
      this.setState({
        editSectorErrMsg: "Bitte wähle ein gültiges Start-und Enddatum aus.",
      });
    }
  };

  handleWeekDaySectionChange = (val: string) => {
    this.setState({ weekDaySection: val });
  };

  requestToDelete = () => {
    this.setState({
      deleteCoursesInRowLoading: true,
      showConfirmModalDeleteInRow: true,
    });
    fetchRetry(
      "deleteCoursesInRow",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        followId: this.state.course.followId,
        confirm: false,
      },
      1,
      5
    )
      .then(this.handleCheckDeleteCoursesInRow)
      .catch(this.handleError);
  };

  handleCheckDeleteCoursesInRow = ({ data }: any) => {
    this.setState({ deleteCoursesInRowLoading: false });
    if (data.success) {
      const deleteCoursesArr = data.data.sort((a: any, b: any) =>
        a.activeBookings > b.activeBookings ? 1 : -1
      );
      this.setState({ deleteCoursesArr });
    }
  };

  hideConfirmationDeleteInRowModal = () => {
    this.setState({ showConfirmModalDeleteInRow: false });
  };

  handleDeleteCourseInRowConfirmed = () => {
    this.setState({
      deleteCoursesInRowLoading: true,
      showConfirmModalDeleteInRow: false,
    });
    fetchRetry(
      "deleteCoursesInRow",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        followId: this.state.course.followId,
        confirm: true,
      },
      1,
      5
    )
      .then(this.handleDeleteCoursesInRow)
      .catch(this.handleError);
  };

  handleDeleteCoursesInRow = ({ data }: any) => {
    this.setState({ deleteCoursesInRowLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Die Kurse wurde erfolgreich gelöscht.",
        "success",
        4
      );
      this.setState({
        errorMessageDelete: null,
        redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course`,
      });
    } else {
      this.setState({ errorMessageDelete: data.errorMsgDe });
    }
  };

  toggleMinAge = () => {
    this.setState({ hasMinAge: !this.state.hasMinAge }, this.checkChange);
  };

  toggleMaxAge = () => {
    this.setState({ hasMaxAge: !this.state.hasMaxAge }, this.checkChange);
  };

  handleMaxAgeChange = (val: number) => {
    this.setState({ maxAge: val }, this.checkChange);
  };

  handleMinAgeChange = (val: number) => {
    this.setState({ minAge: val }, this.checkChange);
  };

  showNotificationModal = () => {
    this.setState({ showNotificationModal: true });
  };

  hideNotificationModal = () => {
    this.setState({ showNotificationModal: false });
  };

  handleBranchRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.branchRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ branchRoleArr: newRoleArr }, this.checkChange);
  };

  handleBranchRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.branchRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ branchRoleArr: newRoleArr }, this.checkChange);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    if (!this.state.branchLoaded || !this.state.courseLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    const selectedRolesWithDefault =
      this.state.roleArr.filter(
        (currRole: any) => currRole.id === "guest" && currRole.selected
      ).length !== 0
        ? this.state.roleArr
            .filter(
              (currRole: any) => currRole.id !== "guest" && currRole.selected
            )
            .map((currRole: any) => currRole.name)
        : [];

    const selectedRolesWithMemberOfBranch =
      this.state.roleArr.filter(
        (currRole: any) => currRole.id === "public" && currRole.selected
      ).length !== 0
        ? this.state.roleArr
            .filter(
              (currRole: any) =>
                !["public", "guest"].includes(currRole.id) && currRole.selected
            )
            .map((currRole: any) => currRole.name)
        : [];

    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg="Willst du diesen Kurs wirklick löschen? Diese Aktion kann nicht rückgängig gemacht werden."
          handleConfirm={this.handleDeleteCourseConfirmed}
        />
        <CourseNotificationModal
          show={this.state.showNotificationModal}
          handleClose={this.hideNotificationModal}
          title="Benachrichtigungs Einstellungen"
          clubId={this.state.clubId}
          branchId={this.state.branchId}
          courseId={this.state.courseId}
          isInfinityCourse={this.state.course.courseType === "infinity-course"}
        />
        <ConfirmationDeleteCoursesInRowModal
          show={this.state.showConfirmModalDeleteInRow}
          handleClose={this.hideConfirmationDeleteInRowModal}
          title="Bist du sicher?"
          msg="Willst du wirklich alle Kurs in dieser Reihe wirklick löschen? Diese Aktion kann nicht rückgängig gemacht werden."
          handleConfirm={this.handleDeleteCourseInRowConfirmed}
          loading={this.state.deleteCoursesInRowLoading}
          courseArr={this.state.deleteCoursesArr}
          clubId={this.state.clubId}
          branchId={this.state.branchId}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <div
              className="subscreen-bell-container"
              onClick={this.showNotificationModal}
            >
              <FontAwesomeIcon icon={faBell} />
            </div>
            <BackElement
              text="zurück zur Kursverwaltung"
              to={`/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course#${this.state.courseId}`}
            />

            <Title title="Kurs bearbeiten" />
            <div className="upload-main-container margin-bottom">
              <div className="image-container">
                <label className="image-upper-container">
                  <FontAwesomeIcon
                    icon={faImage}
                    size="4x"
                    className="image-icon"
                  />
                  <input
                    type="file"
                    id="uploadCourseImg"
                    className="fileInput"
                    onChange={this.handleImageUpload}
                  />
                  <span>Kursbild auswählen.</span>
                  <progress
                    value={this.state.courseImgProgress}
                    max="100"
                    id="uploaderBanner"
                    className="progress-bar"
                  />
                </label>
                <div className="image-lower-container">
                  <button
                    id="delete-courseImg"
                    className="delete-button"
                    onClick={this.deleteCourseImg}
                  >
                    Kursbild löschen
                  </button>
                </div>
              </div>
              <div className="upload-logo-container">
                {(!this.state.course.imgLowRes && !this.state.previewUrl) ||
                this.state.courseImgRemoved ? (
                  <div className="background-container">
                    <FontAwesomeIcon
                      icon={faImage}
                      size="4x"
                      className="image-icon"
                    />
                    Kein Kursbild
                  </div>
                ) : (
                  <img
                    src={
                      this.state.previewUrl &&
                      this.state.courseImgProgress >= 100
                        ? this.state.previewUrl
                        : this.state.course.imgLowRes
                    }
                    alt="Kursbild"
                  />
                )}
              </div>
            </div>
            <SubTitleTwo title="Allgemeine Einstellungen" />
            <div className="course-edit-container">
              <SubTitle title="Kurstyp" />
              <div className="margin-bottom">
                {courseTypes[this.state.course.courseType]?.name}
              </div>
              <SubTitle
                title="Name"
                helpText="Der Kursname wird jedem der diesen Kurs sieht angezeigt."
              />
              <Input
                name="course-name-input"
                value={this.state.courseTitle}
                placeholder="Kurs Name"
                onChange={this.handleUpdateTitle}
              />
              <SubTitle
                title="Beschreibung"
                helpText="Die Beschreibung wird jedem der diesen Kurs sieht angezeigt."
              />
              <Input
                name="course-description-input"
                value={this.state.courseDescription}
                placeholder="Kurs Beschreibung"
                onChange={this.handleUpdateDescription}
              />
              <SubTitle
                title="Örtlichkeit"
                helpText={
                  this.state.course.courseType === "block-course"
                    ? "Örtlichkeiten können in den Bereich Einstellungen verwaltet werden. Da einzelne Termine unterschiedliche Örtlichkeiten haben können, ist dies nur der Standardwert, wenn neue Termine hinzugefügt werden."
                    : "Örtlichkeiten können in den Bereich Einstellungen verwaltet werden."
                }
              />
              <DropDown
                options={this.state.places}
                onChange={this.handlePlaceChange}
                value={this.state.selectedPlace}
                name="locations-dropdown"
                default={"-"}
              />
              <SubTitle
                title="Plätze"
                helpText="Die Anzahl an Leuten die diesen Kurs buchen können."
              />
              <Input
                name="role-name-input"
                value={this.state.coursePlaces}
                placeholder="Plätze"
                onChange={this.handleUpdatePlaces}
                type="number"
                step={1}
              />
              <SubTitle
                title="Erlaubte Rollen"
                helpText={`Die ausgewählten Rollen können diesen Kurs buchen. Um das Buchen von unregistrierten Benutzern zu erlauben, wähle "Jeder".`}
              />
              <MultiSelector
                searchTextPlaceholder="Rolle"
                arr={this.state.roleArr}
                onSelect={this.handleRoleSelect}
                onUnselect={this.handleRoleUnselect}
              />
              <InfoContainer
                active={selectedRolesWithDefault.length > 0}
              >{`Die Einstellung "Jeder" beinhaltet die ${
                selectedRolesWithDefault.length === 1 ? "Rolle" : "Rollen"
              } "${selectedRolesWithDefault
                .slice(
                  0,
                  selectedRolesWithDefault.length === 1
                    ? 1
                    : selectedRolesWithDefault.length - 1
                )
                .join('", "')}${
                selectedRolesWithDefault.length > 1
                  ? `" und "${
                      selectedRolesWithDefault[
                        selectedRolesWithDefault.length - 1
                      ]
                    }`
                  : ""
              }". Daher ${
                selectedRolesWithDefault.length === 1 ? "muss" : "müssen"
              } sie nicht extra ausgewählt werden.`}</InfoContainer>
              <InfoContainer
                active={selectedRolesWithMemberOfBranch.length > 0}
              >{`Die Einstellung "Registrierte Benutzer" beinhaltet die ${
                selectedRolesWithMemberOfBranch.length === 1
                  ? "Rolle"
                  : "Rollen"
              } "${selectedRolesWithMemberOfBranch
                .slice(
                  0,
                  selectedRolesWithMemberOfBranch.length === 1
                    ? 1
                    : selectedRolesWithMemberOfBranch.length - 1
                )
                .join('", "')}${
                selectedRolesWithMemberOfBranch.length > 1
                  ? `" und "${
                      selectedRolesWithMemberOfBranch[
                        selectedRolesWithMemberOfBranch.length - 1
                      ]
                    }`
                  : ""
              }". Daher ${
                selectedRolesWithMemberOfBranch.length === 1 ? "muss" : "müssen"
              } sie nicht extra ausgewählt werden.`}</InfoContainer>
              <SubTitle
                title={
                  this.state.course.courseType === "single-course" ||
                  this.state.course.courseType === "block-course"
                    ? "Preis"
                    : "Preis pro Monat"
                }
                helpText={
                  this.state.course.courseType === "single-course" ||
                  this.state.course.courseType === "block-course"
                    ? "Einmaliger Preis des Kurses."
                    : "Monatlicher Preis des Kurses."
                }
              />
              <table className="table-price">
                <thead>
                  <tr>
                    <th>Löschen</th>
                    <th>Rolle</th>
                    <th>Preis (in €)</th>
                    <th>Immer anzeigen</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rolePriceArr
                    .filter((role: any) => role.selected)
                    .map((role: any) => (
                      <tr>
                        {role.id !== "default" ? (
                          <td
                            onClick={() => {
                              this.handleRolePriceUnselect(role.id);
                            }}
                            className="pointer center"
                          >
                            <FontAwesomeIcon icon={faTimes} size="lg" />
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td>
                          <div className="flex">
                            {role.name}{" "}
                            {role.id === "default" && (
                              <div className="info-icon-container margin-left">
                                <FontAwesomeIcon
                                  icon={faQuestionCircle}
                                  size="1x"
                                  onClick={() => {}}
                                  className="back-element-icon"
                                />
                                <div className="info-text-container">
                                  <div className="info-text-triangle"></div>
                                  <div className="info-text-inner-container">
                                    Der Preis, wenn das Mitglied keine der
                                    ausgewählten Rollen hat. Auch der Preis für
                                    Gäste und nicht Mitglieder.
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <Input
                            value={role.val}
                            onChange={(val: number) => {
                              this.handlePriceRoleChange(role.id, val);
                            }}
                            type="number"
                            placeholder="Preis"
                            className="no-margin"
                          />
                        </td>
                        <td>
                          <TextCheckbox
                            val={role.showAlways}
                            onChange={() => {
                              this.handleShowAlwaysChange(role.id);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  <tr className="bold">
                    <td
                      className="center pointer"
                      onClick={this.handleAddPriceRole}
                    >
                      +
                      <div
                        className={`multiselector-add-container${
                          this.state.showAddRolePricing
                            ? " multiselector-show-add-container"
                            : ""
                        }`}
                        ref={this.wrapperRef}
                      >
                        <div className="multiselector-add-text-container">
                          <input
                            placeholder="Rolle"
                            value={this.state.searchTextPriceRole}
                            onChange={this.handleChangeSearchTextPriceRole}
                          />
                          <div className="icon-container">
                            <FontAwesomeIcon icon={faSearch} size="1x" />
                          </div>
                        </div>
                        <div className="multiselector-scroll-container">
                          {this.state.rolePriceArr
                            .filter(
                              (item: any) =>
                                !item.selected &&
                                item.name
                                  .toLowerCase()
                                  .includes(
                                    this.state.searchTextPriceRole.toLowerCase()
                                  )
                            )
                            .map((item: any) => (
                              <div
                                className="multiselector-select-element-container"
                                onClick={() => {
                                  this.handleRolePriceSelect(item.id);
                                }}
                                key={item.id}
                              >
                                <div
                                  className="point"
                                  style={{ backgroundColor: item.color }}
                                ></div>
                                {item.name}
                              </div>
                            ))}
                        </div>
                      </div>
                    </td>
                    <td colSpan={3}></td>
                  </tr>
                </tbody>
              </table>
              <SubTitle
                title="Buchungsnotiz"
                helpText="Text der bei einer erfolgreichen Buchung angezeigt und per E-Mail mitgesendet wird."
              />
              <TextArea
                value={this.state.courseBookingNote}
                onChange={this.handleBookingNoteChange}
                placeholder="Buchungsnotiz"
              />
              {(this.state.course.courseType === "single-course" ||
                this.state.course.courseType === "block-course") && (
                <>
                  <SubTitle
                    title="Stornierungsfrist (in Tagen)"
                    helpText="Vor dieser Anzahl an Tagen vor Kursbeginn kann der Kurs storniert werden."
                  />

                  <div className="course-time-input-container">
                    <div className="margin-right">
                      <Switch
                        value={this.state.hasCancellationAllowed}
                        onChange={this.toggleCancellationAllowed}
                      />
                    </div>
                    {this.state.hasCancellationAllowed ? (
                      <Input
                        value={this.state.cancellationDays}
                        onChange={this.handleCancellationDaysChange}
                        type="number"
                        min={0}
                      />
                    ) : (
                      <div className="input-container expireDate-container">
                        Keine Stornierungen erlaubt
                      </div>
                    )}
                  </div>
                </>
              )}
              {/* <SubTitle
                title="Sichtbar ab x Tagen vor Kursbeginn"
                helpText="Vor dieser Anzahl an Tagen vor Kursbeginn wird der Kurs angezeigt."
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasVisibleFromAllowed}
                    onChange={this.toggleVisibleFromAllowed}
                  />
                </div>
                {this.state.hasVisibleFromAllowed ? (
                  <Input
                    value={this.state.visibleDays}
                    onChange={this.handleVisibleFromDaysChange}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Immer Sichtbar
                  </div>
                )}
              </div> */}
              <SubTitle
                title="Buchbar ab x Tagen vor Kursbeginn"
                helpText="Vor dieser Anzahl an Tagen vor Kursbeginn kann der Kurs gebucht werden."
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasBookableFromAllowed}
                    onChange={this.toggleBookableFromAllowed}
                  />
                </div>
                {this.state.hasBookableFromAllowed ? (
                  <Input
                    value={this.state.bookableDays}
                    onChange={this.handleBookableFromChange}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Immer Buchbar
                  </div>
                )}
              </div>
              <SubTitle
                title="Mindestalter"
                helpText="Ab diesem Alter kann der Kurs gebucht werden. (Dieses Alter kann den Kurs buchen)"
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasMinAge}
                    onChange={this.toggleMinAge}
                  />
                </div>
                {this.state.hasMinAge ? (
                  <Input
                    value={this.state.minAge}
                    onChange={this.handleMinAgeChange}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Kein Mindestalter
                  </div>
                )}
              </div>
              <SubTitle
                title="Maximalalter"
                helpText="Nach diesem Alter kann der Kurs nicht mehr gebucht werden. (Dieses Alter kann den Kurs buchen)"
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasMaxAge}
                    onChange={this.toggleMaxAge}
                  />
                </div>
                {this.state.hasMaxAge ? (
                  <Input
                    value={this.state.maxAge}
                    onChange={this.handleMaxAgeChange}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Kein Maximalalter
                  </div>
                )}
              </div>
              <SubTitle
                title="Erlaube Buchungen für Familienmitglieder"
                helpText="Stellt ein, ob ein Kunde für seine Familienmitglieder buchen kann."
              />
              <Switch
                value={this.state.allowBookForFamily}
                onChange={() => {
                  this.setState(
                    {
                      allowBookForFamily: !this.state.allowBookForFamily,
                    },
                    this.checkChange
                  );
                }}
              />
              <div className="small-space"></div>
              <SubTitle
                title="Tags"
                helpText="Tags können hinzugefügt werden um die Suche nach Kurse zu vereinfachen. Tags können in den Bereich Einstellungen verwaltet werden."
              />
              <MultiSelector
                searchTextPlaceholder="Tags"
                arr={this.state.tagArr}
                onSelect={this.handleTagSelect}
                onUnselect={this.handleTagUnselect}
              />
              <SubTitle
                title="diese Rollen automatisch zuweisen"
                helpText="Die ausgewählten Rollen werden dem Nutzer beim Buchen des Kurses automatisch zugewiesen. Beim stornieren des Kurses werden die Rollen nicht wieder weggenommen."
              />
              <MultiSelector
                searchTextPlaceholder="Rolle"
                onSelect={this.handleBranchRoleSelect}
                onUnselect={this.handleBranchRoleUnselect}
                arr={this.state.branchRoleArr}
                className="margin-bottom"
              />

              {this.state.branch?.settings?.useForm && (
                <>
                  <SubTitle
                    title="Formular"
                    helpText="Verknüpfe ein Formular mit diesem Kurs, jeder Nutzer muss beim Buchen des Kurses das Formular ausfüllen. Formulare können in den Bereichseinstellungen verwaltet werden."
                  />
                  <DropDown
                    options={this.state.forms}
                    onChange={this.handleFormChange}
                    value={this.state.selectedForm}
                    name="form-dropdown"
                    default={"-"}
                  />
                </>
              )}

              {this.state.course.courseType === "infinity-course" && (
                <>
                  <SubTitleTwo title="Termine" />
                  <SubTitle title="Uhrzeit" helpText="Startzeit des Kurses." />
                  <div className="course-time-input-container">
                    <input
                      type="time"
                      className="input-container"
                      value={this.state.infinityCourseTime}
                      onChange={this.handleInfinityTimeChange}
                    />
                  </div>
                  <SubTitle title="Dauer (in min)" />
                  <div className="course-date-input-container">
                    <Input
                      name="role-name-input"
                      value={this.state.courseDuration}
                      placeholder="Dauer"
                      onChange={this.handleUpdateDuration}
                      type="number"
                    />
                  </div>
                  <SubTitle
                    title="Anfangsdatum"
                    helpText="Ab diesem Zeitpunkt finden Termine dieses Kurses statt (Dieser Tag eingeschlossen). Ein angebrochener Monat wird als vollständig abgerechnet."
                  />
                  <div className="course-time-input-container">
                    <input
                      type="date"
                      className="input-container"
                      value={this.state.infinityStartDate}
                      onChange={(val: any) => {
                        this.handleInfinityStartDateChange(val.target.value);
                      }}
                    />
                  </div>
                  <SubTitle
                    title="Auslaufdatum"
                    helpText="An diesem Tag wird der Kurs auslaufen (Dieser Tag findet noch statt). Ein angebrochener Monat wird als vollständig abgerechnet."
                  />

                  <div className="course-time-input-container">
                    <div className="margin-right">
                      <Switch
                        value={this.state.hasExpireDate}
                        onChange={this.toggleHasExpireDate}
                      />
                    </div>
                    {this.state.hasExpireDate ? (
                      <input
                        type="date"
                        className="input-container expireDate-container"
                        value={this.state.infinityEndDate}
                        onChange={(val: any) => {
                          this.handleInfinityEndDateChange(val.target.value);
                        }}
                      />
                    ) : (
                      <div className="input-container expireDate-container">
                        Kein Auslaufdatum
                      </div>
                    )}
                  </div>
                  <SubTitle
                    title="Wöchentliche Wiederholung"
                    helpText="Der Kurs findet jede Woche an diesem Wochentag statt."
                  />
                  <DropDown
                    options={weekDays}
                    onChange={this.handleWeekDayChange}
                    value={this.state.weekDay}
                    name="locations-dropdown"
                  />
                  <SubTitle
                    title="Kündigungsfrist"
                    helpText="Frist bis zum Wirksamwerden der Kündigung."
                  />
                  <div className="flex two-input-container">
                    <DropDown
                      options={dateUnits}
                      onChange={this.handleDateUnitsChange}
                      value={this.state.dateUnit}
                      name="locations-dropdown"
                    />
                    <Input
                      value={this.state.noticePeriod}
                      onChange={this.handleNoticePeriodChange}
                      type="number"
                      min={0}
                      step={1}
                    />
                  </div>
                  <Calendar
                    setSelectedDates={() => {}}
                    onSelectedNegativeDatesChange={
                      this.onSelectedNegativeDatesChange
                    }
                    weekDay={parseInt(this.state.weekDay)}
                    negativeDates={this.state.negativeDates}
                    startDate={inputStrToDate(this.state.infinityStartDate)}
                    endDate={
                      this.state.hasExpireDate && this.state.infinityEndDate
                        ? inputStrToDate(this.state.infinityEndDate)
                        : null
                    }
                    selectedDates={this.state.selectedDates}
                    ref={this.calendarRef}
                  />
                </>
              )}
              {this.state.course.courseType === "single-course" && (
                <>
                  <SubTitleTwo title="Termine" />
                  <SubTitle
                    title="Start Datum/Uhrzeit"
                    helpText="Startzeitpunkt des Kurses."
                  />
                  <div className="course-time-input-container">
                    <input
                      type="date"
                      className="input-container"
                      value={this.state.courseDate}
                      onChange={this.handleDateChange}
                    />
                    <input
                      type="time"
                      className="input-container"
                      value={this.state.courseTime}
                      onChange={this.handleTimeChange}
                    />
                  </div>
                  <SubTitle
                    title="End Datum/Uhrzeit"
                    helpText="Endzeitpunkt des Kurses."
                  />
                  <div className="course-time-input-container">
                    <input
                      type="date"
                      className="input-container"
                      value={this.state.courseEndDate}
                      onChange={this.handleEndDateChange}
                    />
                    <input
                      type="time"
                      className="input-container"
                      value={this.state.courseEndTime}
                      onChange={this.handleEndTimeChange}
                    />
                  </div>
                </>
              )}
              {this.state.course.courseType === "block-course" && (
                <>
                  <SubTitleTwo title="Termine" />
                  <SubTitle title="Uhrzeit" helpText="Startzeit des Kurses." />
                  <div className="course-time-input-container">
                    <input
                      type="time"
                      className="input-container"
                      value={this.state.courseTime}
                      onChange={this.handleTimeChange}
                    />
                  </div>
                  <SubTitle title="Dauer (in min)" />
                  <div className="course-date-input-container">
                    <Input
                      name="role-name-input"
                      value={this.state.courseDuration}
                      placeholder="Dauer"
                      onChange={this.handleUpdateDuration}
                      type="number"
                    />
                  </div>
                  <SubTitle
                    title="Termin Bereich auswählen"
                    helpText="Erstelle oder lösche hiermit ganze Bereiche von Terminen."
                  />
                  <DateSectionSelector
                    sectorStartDate={this.state.sectorStartDate}
                    sectorEndDate={this.state.sectorEndDate}
                    handleSectorStartDateChange={
                      this.handleSectorStartDateChange
                    }
                    handleSectorEndDateChange={this.handleSectorEndDateChange}
                    addSector={this.addSector}
                    removeSector={this.removeSector}
                    editSectorErrMsg={this.state.editSectorErrMsg}
                    weekDaySection={this.state.weekDaySection}
                    handleWeekDaySectionChange={this.handleWeekDaySectionChange}
                  />
                  <Calendar
                    setSelectedDates={this.onSelectedDatesChange}
                    onSelectedNegativeDatesChange={() => {}}
                    selectedDates={this.state.selectedDates}
                    ref={this.calendarRef}
                  />
                  <div
                    className="more-info-container"
                    onClick={this.toggleShowDates}
                  >
                    <div className="more-info-inner-container">
                      {this.state.showDates ? (
                        <FontAwesomeIcon icon={faChevronDown} size="sm" />
                      ) : (
                        <FontAwesomeIcon icon={faChevronUp} size="sm" />
                      )}
                    </div>
                    {this.state.dates.length} Termine ausgewählt
                  </div>
                  {this.state.showDates && (
                    <>
                      {this.state.dates.map((date: any, index: number) => (
                        <DateComponent
                          date={reformatDateReverse(date.dateStr)}
                          endDate={reformatDateReverse(date.endDateStr)}
                          courseTime={date.time}
                          endTime={date.endTime}
                          handleTimeChange={(time: string) => {
                            this.onTimeChange(index, time);
                          }}
                          handleEndTimeChange={(time: string) => {
                            this.onEndTimeChange(index, time);
                          }}
                          handleLocationChange={(location: string) => {
                            this.onLocationChange(index, location);
                          }}
                          handleEndDateChange={(date: string) => {
                            this.onEndDateChange(index, date);
                          }}
                          places={this.state.places}
                          selectedLocation={date.location}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
              <ErrorMessage message={this.state.errorMessage} />
              <SecButton
                change={this.state.change}
                color="green"
                loading={this.state.updateLoading}
                onClick={this.updateCourse}
                title="Speichern"
              />
              <ErrorMessage message={this.state.errorMessageDelete} />
              <SecButton
                change={true}
                color="red"
                loading={this.state.deleteLoading}
                onClick={this.handleDeleteCourse}
                title="Kurs löschen"
              />
              {this.state.course.courseType === "single-course" && (
                <>
                  <SubTitle
                    title="Für Kurse in Reihe übernehmen"
                    helpText="Übernehme die ausgewählten Eigenschaften auf alle Kurse in dieser Reihe."
                  />
                  <div className="adopt-settings-container">
                    <TextCheckbox
                      text="Kursbild"
                      val={this.state.setFollowUpImage}
                      onChange={() => {
                        this.setState({
                          setFollowUpImage: !this.state.setFollowUpImage,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Name"
                      val={this.state.setFollowUpName}
                      onChange={() => {
                        this.setState({
                          setFollowUpName: !this.state.setFollowUpName,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Beschreibung"
                      val={this.state.setFollowUpDescription}
                      onChange={() => {
                        this.setState({
                          setFollowUpDescription:
                            !this.state.setFollowUpDescription,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Örtlichkeit"
                      val={this.state.setFollowUpLocation}
                      onChange={() => {
                        this.setState({
                          setFollowUpLocation: !this.state.setFollowUpLocation,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Plätze"
                      val={this.state.setFollowUpPlaces}
                      onChange={() => {
                        this.setState({
                          setFollowUpPlaces: !this.state.setFollowUpPlaces,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Erlaubte Rollen"
                      val={this.state.setFollowUpRoles}
                      onChange={() => {
                        this.setState({
                          setFollowUpRoles: !this.state.setFollowUpRoles,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="automatische zugewiesene Rollen"
                      val={this.state.setFollowUpManageRoles}
                      onChange={() => {
                        this.setState({
                          setFollowUpManageRoles:
                            !this.state.setFollowUpManageRoles,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Preis"
                      val={this.state.setFollowUpPrice}
                      onChange={() => {
                        this.setState({
                          setFollowUpPrice: !this.state.setFollowUpPrice,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Buchungsnotiz"
                      val={this.state.setFollowUpBookingnote}
                      onChange={() => {
                        this.setState({
                          setFollowUpBookingnote:
                            !this.state.setFollowUpBookingnote,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Stornierungsfrist"
                      val={this.state.setFollowUpCancellation}
                      onChange={() => {
                        this.setState({
                          setFollowUpCancellation:
                            !this.state.setFollowUpCancellation,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Buchbar ab"
                      val={this.state.setBookableFrom}
                      onChange={() => {
                        this.setState({
                          setBookableFrom: !this.state.setBookableFrom,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Tags"
                      val={this.state.setTags}
                      onChange={() => {
                        this.setState({
                          setTags: !this.state.setTags,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Mindestalter"
                      val={this.state.setMinAge}
                      onChange={() => {
                        this.setState({
                          setMinAge: !this.state.setMinAge,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="Maximalalter"
                      val={this.state.setMaxAge}
                      onChange={() => {
                        this.setState({
                          setMaxAge: !this.state.setMaxAge,
                        });
                      }}
                    />
                    <TextCheckbox
                      text="buchen für Familienmitglieder"
                      val={this.state.setAllowBookForFamily}
                      onChange={() => {
                        this.setState({
                          setAllowBookForFamily:
                            !this.state.setAllowBookForFamily,
                        });
                      }}
                    />
                  </div>
                  <ErrorMessage
                    message={this.state.errorMessageUpdateCoursesInRow}
                  />
                  <SecButton
                    change={
                      this.state.setFollowUpBookingnote ||
                      this.state.setFollowUpCancellation ||
                      this.state.setFollowUpDescription ||
                      this.state.setFollowUpImage ||
                      this.state.setFollowUpLocation ||
                      this.state.setFollowUpName ||
                      this.state.setFollowUpPlaces ||
                      this.state.setFollowUpPrice ||
                      this.state.setFollowUpRoles ||
                      this.state.setFollowUpManageRoles ||
                      this.state.setBookableFrom ||
                      this.state.setTags ||
                      this.state.setMinAge ||
                      this.state.setMaxAge ||
                      this.state.setAllowBookForFamily
                    }
                    color="green"
                    loading={this.state.updateInRowLoading}
                    onClick={this.updateCoursesInRow}
                    title="Für alle übernehmen"
                  />
                  <ErrorMessage
                    message={this.state.errorMessageDeleteCoursesInRow}
                  />
                  <SecButton
                    change={true}
                    color="red"
                    loading={this.state.deleteCoursesInRowLoading}
                    onClick={this.requestToDelete}
                    title="alle Kurs in Reihe löschen"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
